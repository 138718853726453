.path-innovations {
	#block-upwing-content .node__content {

		margin-bottom: 5rem;

		@include bp(m) {
			margin-bottom: 10rem;
		}

		.align-right {
			@include bp(s_max) {
				float: none;
				max-width: 20rem;
				margin: 0 calc(50% - 10rem) 3rem;
			}
		}

		ol {
			@include bp(s) {
				max-width: 60%;
			}
		}

		p.intro {
			@include aa;
			text-align: center;
			padding: 3rem 0;
			width: 90%;
			max-width: 86rem;
			margin-left: auto;
			margin-right: auto;
			font-size: 1.6rem;
			line-height: 2.2rem;

			@include bp(m) {
				padding: 5rem 0;
				line-height: 3.2rem;
				font-size: 2.4rem;
			}

			&:after {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}	
}

body[class*='path-innovations-'] {
	#block-upwing-page-title {
		h1 {
			opacity: 0;
		}
	}
}