.button {
	@include aa;
	position: relative;
	padding: 1rem 4rem;
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.6rem;
	border: 0.1rem solid $blue;
	background: $white;
	color: $blue;

	&:after {
		content: '';
		display: inline-block;
		width: 17px;
		height: 12px;
		position: relative;
		top: -1px;
		margin-left: 10px;
		vertical-align: middle;
		background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%2300aeef'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E");
	}

	&:hover {
		background: $blue;
		color: $white;

		&:after {
			background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%23fff'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E");
		}
	}
	
	&.solid {
		background: $blue;
		color: $white;

		&:after {
			display: none;
		}

		&:hover {
			background: $white;
			color: $blue;
		}
	}
}

.button--download {
	display: inline-block;
	background: $navy-blue url('#{$path-img}/download.svg') right 3rem top .85rem no-repeat;
	color:$white !important;
	padding: 1.5rem 7.5rem 1.5rem 3rem;
	font-weight: 300;
}