.node--type-about-page {
	.layout-container {
		&:before {
			display: none;
		}
	}

	main[role="main"].no-page-header [class^="layout-"] {
		padding: 0;
	}

	#block-upwing-page-title {
		display: none;
	}
	#block-upwing-content {
		.node {
			&__content {
				text-align: center;
				color: $dark-gray;
				@include bp(l) {
					max-width: 102rem;
					margin-left: auto;
					margin-right: auto;
				}

				h1 {
					color: $dark-blue;
				}

				.intro {
					padding-left: 8%;
					padding-right: 8%;
					line-height: 1.2;

					&:after {
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
			&__hero {
				@include full-width;
				margin-top: 0;
				margin-bottom: 3rem;
				position: relative;

				@include bp(m) {
					margin-bottom: 10rem;
					margin-top: -3vw;
				}

				@include bp(xxl) {
					margin-top: -10vw;
				}

				&:before,
				&:after {
					content:"";
					position: absolute;
					z-index: -1;
					top: calc(100% - 10rem);

					@include bp(m_max) {
						display: none;
					}
				}

				&:before {
					left: 10%;
					width: 90%;
					background-image: url('#{$path-img}/light-blue.png');
					background-size: contain;
					background-repeat: no-repeat;
					padding-bottom: calc(90% / 3.32);
					transform: rotate(180deg);
				}

				&:after {
					right: 48%;
					width: 52%;
					background-image: url('#{$path-img}/dark-blue.png');
					background-size: contain;
					background-repeat: no-repeat;
					padding-bottom: calc(52% / 3.58);
					transform: rotate(180deg);
				}

				img {
					width: 100%;
					max-width: 100%;
					height: auto;
				}
			}
			&__bottom {

				@include bp(m_max) {
					padding: 0 2rem;
				}

				.bottom-item {
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					margin: 4rem 0 1.5rem;

					@include bp(m) {
						flex-flow: row nowrap;
						margin: 8rem 0 3rem;
					}

					&:nth-child(even) {
						@include bp(m) {
							flex-flow: row-reverse nowrap;
						}
					}

					&__image {
						overflow: hidden;
						width: 80%;
						max-width: 40rem;
						margin: 0 auto;

						@include bp(m) {
							flex: 0 0 40%;
							height:0;
							padding-bottom: 40%;
							margin: 0;
						}

						img {
							border-radius: 50%;
						}
					}

					&__content {
						@include bp(m) {
							flex: 0 0 50%;
							padding-right: 8%;
						}
					}
					&__title {
						@include aa;
						color: $dark-blue;
						font-size: 2.1rem;
						font-weight: 500;
						margin-top: 0;

						@include bp(m) {
							font-size: 2.4rem;
						}

						@include bp(xl) {
							font-size: 3rem;
						}
					}
					&__text {
						font-size: 1.6rem;

						a {
							color: $blue;
						}
					}
				}
			}
		}
	}
}

// Animate bottom items
.js .path-whatwereabout {
	.node__bottom .bottom-item {
		opacity: 0;
		transform: translateY(5rem);
		transition: all .25s ease-in;

		&.in-view {
			opacity: 1;
			transform: none;
		}
	}
}