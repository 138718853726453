@import "//fonts.googleapis.com/css?family=Roboto:100,100i,300,400,400i,500,700&display=swap";
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  background-color: #fff; }
*, *:before, *:after {
  box-sizing: inherit; }
html.with-featherlight {
  overflow: visible; }
.featherlight {
  overflow: auto;
  width: 100vw; }
.featherlight .featherlight-content {
    overflow: visible;
    max-height: none;
    max-width: 100rem;
    margin: 5rem 10rem; }
.featherlight-iframe .featherlight-content {
    max-width: none; }
.featherlight button.featherlight-close {
  color: #fff;
  background: #000;
  border: 0.3rem solid #fff;
  border-radius: 50%;
  font-size: 2.5rem;
  height: 4rem;
  width: 4rem;
  line-height: 3rem;
  text-align: center;
  margin: -2rem -2rem 0 0;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15); }
.sticky {
  position: -webkit-sticky;
  position: sticky; }
.sticky:before, .sticky:after {
    content: "";
    display: table; }
body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  height: 1px;
  min-height: 100vh;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #6f6f6f;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
hr {
  border: none;
  height: .1rem;
  background: #d8e3e9;
  margin: 4rem 0 4.5rem; }
figure {
  padding: 0;
  margin: 0; }
figure figcaption {
    margin: 1.5rem 0 3rem;
    font-size: 1.6rem;
    font-style: italic; }
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }
img.align-left {
    max-width: 35%;
    margin-right: 5%; }
img.align-right {
    max-width: 35%;
    margin-left: 5%; }
@media (min-width: 800px) {
    img.align-center {
      max-width: 50%; } }
p {
  margin: 2rem 0; }
a {
  text-decoration: none; }
.blue-tri {
  position: relative; }
.blue-tri:after {
    content: "";
    display: inline-block;
    margin-left: .8rem;
    height: 0;
    width: 0;
    border: .5rem solid transparent;
    border-left-color: #0194d3; }
table caption {
  caption-side: bottom;
  font-style: italic; }
@media (min-width: 800px) {
    table caption {
      text-align: left; } }
#block-contactbottom {
  display: none; }
.button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding: 1rem 4rem;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.6rem;
  border: 0.1rem solid #00aeef;
  background: #fff;
  color: #00aeef; }
.button:after {
    content: '';
    display: inline-block;
    width: 17px;
    height: 12px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%2300aeef'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E"); }
.button:hover {
    background: #00aeef;
    color: #fff; }
.button:hover:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%23fff'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E"); }
.button.solid {
    background: #00aeef;
    color: #fff; }
.button.solid:after {
      display: none; }
.button.solid:hover {
      background: #fff;
      color: #00aeef; }
.button--download, hr + p a {
  display: inline-block;

  color: #fff !important;
  padding: 1.5rem 7.5rem 1.5rem 3rem;
  font-weight: 300; }
.node__content--bottom {
  position: relative;
  padding: 5rem 0;
  margin-bottom: -5rem; }
@media (min-width: 800px) {
    .node__content--bottom {
      margin-bottom: -10rem; } }
.node__content--bottom:before {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    content: "";
    background: #e7eef2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    pointer-events: none; }
.node__content--bottom h2 {
    font-weight: 400;
    font-size: 1.6rem;
    color: #65666a; }
@media (min-width: 800px) {
      .node__content--bottom h2 {
        font-size: 2rem; } }
@media (min-width: 1200px) {
      .node__content--bottom h2 {
        font-size: 2.4rem; } }
.node__content--bottom .bottom-item {
    border-top: .1rem solid #d8d8d8;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap; }
.node__content--bottom .bottom-item__title {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0;
      color: #0194d3;
      font-weight: 400; }
.node__content--bottom .bottom-item__text {
      color: #65666a;
      font-size: 1.6rem; }
.node__content--bottom .bottom-item__text p {
        margin: 0;
        font-size: 1.6rem; }
.node__content--bottom .bottom-item__text a {
        margin-top: 1rem;
        display: block;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 1.4rem;
        color: #00aeef;
        transition: color .25s ease; }
.node__content--bottom .bottom-item__text a:hover {
          color: #0194d3; }
@media (min-width: 800px) {
    .node__content--bottom .bottom-items {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap; } }
.node__content--bottom .bottom-items .bottom-item {
    padding: 3rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap; }
@media (min-width: 800px) {
      .node__content--bottom .bottom-items .bottom-item {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap; } }
@media (min-width: 800px) {
      .node__content--bottom .bottom-items .bottom-item__content {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap; } }
@media (min-width: 800px) {
      .node__content--bottom .bottom-items .bottom-item__title {
        -webkit-flex: 0 0 30%;
                flex: 0 0 30%;
        margin-right: 2rem; } }
@media (min-width: 800px) {
      .node__content--bottom .bottom-items .bottom-item__text {
        -webkit-flex: 0 1 auto;
                flex: 0 1 auto; } }
.node__content--bottom .bottom-items.grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap; }
@media (min-width: 800px) {
      .node__content--bottom .bottom-items.grid {
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap; } }
.node__content--bottom .bottom-items.grid .bottom-item {
      padding: 3rem 0;
      border-top: 0; }
@media (min-width: 800px) {
        .node__content--bottom .bottom-items.grid .bottom-item:nth-child(-n+3) {
          border-bottom: 0.1rem solid rgba(0, 174, 239, 0.25); } }
@media (min-width: 800px) {
        .node__content--bottom .bottom-items.grid .bottom-item {
          width: calc(100% / 3);
          -webkit-flex: 0 0 calc(100% / 3);
                  flex: 0 0 calc(100% / 3);
          -webkit-flex-flow: column nowrap;
                  flex-flow: column nowrap;
          padding: 3rem 2rem;
          margin-bottom: 2rem; } }
@media (min-width: 800px) {
        .node__content--bottom .bottom-items.grid .bottom-item__content {
          display: block; } }
.node__content--bottom .bottom-items.grid .bottom-item__title {
        margin-bottom: 1rem;
        -webkit-flex: 0 1 auto;
                flex: 0 1 auto; }
.node__content--bottom .bottom-items.grid .bottom-item__text {
        -webkit-flex: 0 1 auto;
                flex: 0 1 auto; }
.paragraph--type--header-image + .layout-content #block-upwing-page-title {
  display: none; }
#block-upwing-page-title h1 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  color: #0194d3;
  font-size: 4rem;
  line-height: 1.2; }
@media (min-width: 800px) {
    #block-upwing-page-title h1 {
      font-size: 5.6rem; } }
#block-upwing-content .node__content {
  overflow: hidden; }
@media (max-width: 799.5px) {
    #block-upwing-content .node__content img {
      width: 100%; } }
#block-upwing-content .node__content a {
    color: #00aeef; }
#block-upwing-content .node__content h1 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 100;
    font-size: 4rem;
    color: #00aeef;
    margin: 3rem 0; }
@media (min-width: 800px) {
      #block-upwing-content .node__content h1 {
        font-size: 5.6rem;
        margin: 6rem 0 3rem; } }
#block-upwing-content .node__content h1 + p.intro {
    margin-top: 0; }
#block-upwing-content .node__content p.intro {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin: 0;
    padding: 0; }
#block-upwing-content .node__content p.intro:after {
      content: "";
      width: 15rem;
      height: 0.1rem;
      margin: 4rem 0;
      background: #00aeef;
      display: block; }
#block-upwing-content .node__content h2:first-child,
  #block-upwing-content .node__content p.intro + h2 {
    margin-top: 0; }
#block-upwing-content .node__content h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0194d3;
    font-weight: 400;
    font-size: 2.8rem;
    margin-top: 6rem; }
#block-upwing-content .node__content h3 {
    color: #6f6f6f;
    font-size: 2rem;
    font-weight: 500; }
#block-upwing-content .node__content p {
    font-size: 1.6rem; }
#block-upwing-content .node__content ul li {
    margin-bottom: 2rem;
    font-size: 1.6rem; }
#block-upwing-content .node__content ul li ul {
      margin-top: 2rem; }
#block-upwing-content .node__content ul li ul li {
        list-style: none;
        position: relative; }
#block-upwing-content .node__content ul li ul li:before {
          content: "-";
          position: absolute;
          left: -2rem;
          font-weight: 800; }
#block-upwing-content .node__content ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    counter-reset: ol; }
#block-upwing-content .node__content ol li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
#block-upwing-content .node__content ol li {
      position: relative;
      padding-left: 5rem; }
#block-upwing-content .node__content ol li:before {
        counter-increment: ol;
        content: counter(ol);
        position: absolute;
        color: #fff;
        background: #0194d3;
        border-radius: 50%;
        height: 2.8rem;
        width: 2.8rem;
        vertical-align: middle;
        line-height: 2.8rem;
        text-align: center;
        font-size: 1.8rem;
        left: 0; }
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          #block-upwing-content .node__content ol li:before {
            line-height: 1.5; } }
#block-upwing-content .node__content ol li strong {
        color: #0194d3;
        font-weight: 500;
        display: block;
        margin-bottom: -2rem; }
#block-upwing-content .node__content ol li a {
        margin-top: 1rem;
        display: block;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 1.4rem;
        color: #00aeef;
        transition: color .25s ease; }
#block-upwing-content .node__content ol li a:hover {
          color: #0194d3; }
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #block-upwing-content .node__content table {
      table-layout: fixed; } }
#block-upwing-content .node__content table caption {
    margin-top: 2rem; }
@media (max-width: 599.5px) {
    #block-upwing-content .node__content table, #block-upwing-content .node__content table caption, #block-upwing-content .node__content th, #block-upwing-content .node__content tbody, #block-upwing-content .node__content tr, #block-upwing-content .node__content td {
      display: block;
      width: 100% !important; } }
@media (max-width: 599.5px) {
    #block-upwing-content .node__content table img, #block-upwing-content .node__content table caption img, #block-upwing-content .node__content th img, #block-upwing-content .node__content tbody img, #block-upwing-content .node__content tr img, #block-upwing-content .node__content td img {
      max-width: 80%;
      display: block;
      margin-left: auto;
      margin-right: auto; } }
form label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: .3rem;
  font-size: 1.8rem;
  font-weight: 500; }
@media (min-width: 800px) {
    form label {
      margin-bottom: .5rem; } }
form label.form-required:after {
    content: "\00a0*";
    color: #00aeef; }
form input, form select, form textarea {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #6f6f6f;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  padding: 1rem 1.25rem;
  border: 0.1rem solid #d8e3e9;
  border-radius: .5rem;
  transition: all .25s ease; }
form input:focus, form select:focus, form textarea:focus {
    border-color: #6f6f6f; }
form .js-form-type-checkbox label,
form .js-form-type-radio label {
  vertical-align: top;
  display: inline-block;
  margin-bottom: 0; }
form .js-form-type-checkbox input,
form .js-form-type-radio input {
  box-sizing: content-box;
  height: 2rem !important;
  width: 2rem !important;
  padding: 0;

  background-size: 14px auto;
  background-image: none; }
form .js-form-type-checkbox input:checked,
  form .js-form-type-radio input:checked {
 }
/* chronos grid styling */
.chronos-grid {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  box-sizing: border-box; }
.chronos-grid__child {
  display: block;
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 20px; }
.chronos-grid__child:first-child {
  padding-right: 20px; }
.chronos-grid__child:last-child {
  padding-left: 20px; }
.chronos-grid__child img {
  max-width: 100%;
  height: auto; }
.chronos-grid__child > *:first-child {
  margin-top: 0; }
.chronos-grid--6-6 .chronos-grid__child:first-child {
  -webkit-flex-basis: 50%;
          flex-basis: 50%; }
.chronos-grid--6-6 .chronos-grid__child:last-child {
  -webkit-flex-basis: 50%;
          flex-basis: 50%; }
.chronos-grid--4-8 .chronos-grid__child:first-child {
  -webkit-flex-basis: 33.333333%;
          flex-basis: 33.333333%; }
.chronos-grid--4-8 .chronos-grid__child:last-child {
  -webkit-flex-basis: 66.666666%;
          flex-basis: 66.666666%; }
.chronos-grid--8-4 .chronos-grid__child:first-child {
  -webkit-flex-basis: 66.666666%;
          flex-basis: 66.666666%; }
.chronos-grid--8-4 .chronos-grid__child:last-child {
  -webkit-flex-basis: 33.333333%;
          flex-basis: 33.333333%; }
.chronos-grid--3-9 .chronos-grid__child:first-child {
  -webkit-flex-basis: 25%;
          flex-basis: 25%; }
.chronos-grid--3-9 .chronos-grid__child:last-child {
  -webkit-flex-basis: 75%;
          flex-basis: 75%; }
.chronos-grid--9-3 .chronos-grid__child:first-child {
  -webkit-flex-basis: 75%;
          flex-basis: 75%; }
.chronos-grid--9-3 .chronos-grid__child:last-child {
  -webkit-flex-basis: 25%;
          flex-basis: 25%; }
@media (max-width: 599.5px) {
  .chronos-grid {
    display: block; }
  .chronos-grid .chronos-grid__child {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  .chronos-grid__child.is-empty {
    display: none; } }
.chronos-grid {
  text-align: left; }
.chronos-grid p {
    font-size: 16px !important;
    text-align: left; }
.chronos-grid ul,
  .chronos-grid ol {
    text-align: left;
    margin: 10px 0 !important;
    padding: 0 0 0 30px !important;
    background-color: transparent !important; }
.chronos-grid ul li,
    .chronos-grid ol li {
      margin-bottom: 10px !important; }
.text-align-center {
  text-align: center !important; }
.text-align-left {
  text-align: left !important; }
.text-align-right {
  text-align: right !important; }
.text-align-justify {
  text-align: justify !important; }
h3.blue-heading {
  color: #0194d3 !important;
  font-weight: 700 !important;
  font-size: 18px !important; }
footer[role="contentinfo"] {
  background: #0194d3;
  color: #fff;
  padding: 5.5rem 0;
  margin-top: 5rem; }
@media (min-width: 800px) {
    footer[role="contentinfo"] {
      margin-top: 8rem; } }
footer[role="contentinfo"] > div {
    padding-left: 2rem;
    padding-right: 2rem;
    transition: padding .25s ease;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
@media (min-width: 800px) {
      footer[role="contentinfo"] > div {
        padding-left: 3rem;
        padding-right: 3rem; } }
@media (min-width: 1000px) {
      footer[role="contentinfo"] > div {
        padding-left: 4rem;
        padding-right: 4rem; } }
@media (min-width: 1200px) {
      footer[role="contentinfo"] > div {
        padding-left: 5rem;
        padding-right: 5rem;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 160rem; } }
@media (min-width: 800px) {
      footer[role="contentinfo"] > div {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap; } }
footer[role="contentinfo"] a {
    color: #fff; }
footer[role="contentinfo"] p {
    margin: 0; }
header[role="banner"] {
  height: 10.8rem;
  position: relative;
  box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.1); }
header[role="banner"] > div {
    padding-left: 2rem;
    padding-right: 2rem;
    transition: padding .25s ease;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    max-width: 140rem; }
@media (min-width: 800px) {
      header[role="banner"] > div {
        padding-left: 3rem;
        padding-right: 3rem; } }
@media (min-width: 1000px) {
      header[role="banner"] > div {
        padding-left: 4rem;
        padding-right: 4rem; } }
@media (min-width: 1200px) {
      header[role="banner"] > div {
        padding-left: 5rem;
        padding-right: 5rem;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 160rem; } }
@media (min-width: 800px) {
      header[role="banner"] > div {
        padding-top: 1rem; } }
#block-upwing-branding img,
#block-logowhite img {
  width: 17rem;
  height: auto; }
@media (min-width: 600px) {
    #block-upwing-branding img,
    #block-logowhite img {
      width: 20rem;
      height: 4.2rem; } }
.layout-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
.layout-container::before {
    content: "";

    background-size: 50% auto;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 10.8rem;
    left: 0; }
.layout-container header {
    -webkit-flex: 0 0 10.8rem;
            flex: 0 0 10.8rem; }
.layout-container main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
.layout-container footer {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto; }
.logged-in .layout-container::before {
  top: 14.8rem; }
.logged-in.toolbar-fixed .layout-container::before {
  top: 14.8rem; }
@media (min-width: 1000px) {
    .logged-in.toolbar-fixed .layout-container::before {
      top: 14.8rem; } }
.logged-in.toolbar-fixed.toolbar-horizontal.toolbar-tray-open .layout-container::before {
  top: 14.8rem; }
@media (min-width: 1000px) {
    .logged-in.toolbar-fixed.toolbar-horizontal.toolbar-tray-open .layout-container::before {
      top: 18.8rem; } }
main[role="main"] {
  padding-left: 2rem;
  padding-right: 2rem;
  transition: padding .25s ease;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap; }
@media (min-width: 800px) {
    main[role="main"] {
      padding-left: 3rem;
      padding-right: 3rem; } }
@media (min-width: 1000px) {
    main[role="main"] {
      padding-left: 4rem;
      padding-right: 4rem; } }
@media (min-width: 1200px) {
    main[role="main"] {
      padding-left: 5rem;
      padding-right: 5rem;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 160rem; } }
@media (min-width: 1000px) {
    main[role="main"] {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; } }
@media (min-width: 1200px) {
    main[role="main"] {
      max-width: 130rem; } }
main[role="main"].no-page-header [class^='layout-'] {
    padding-top: 8rem; }
@media (min-width: 1000px) {
      main[role="main"].no-page-header [class^='layout-'] {
        padding-top: 10vw; } }
@media (min-width: 1600px) {
      main[role="main"].no-page-header [class^='layout-'] {
        padding-top: 16rem; } }
.layout-content {
  -webkit-flex: 1 1 calc(100% - 24rem);
          flex: 1 1 calc(100% - 24rem);
  -webkit-order: 2;
          order: 2; }
@media (min-width: 1000px) {
    .layout-content {
      width: calc(100% - 28rem); } }
.layout-content:last-child {
    width: 100%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto; }
@media (max-width: 999.5px) {
  main.left-sidebar.no-page-header .layout-content {
    padding-top: 0; } }
.layout-sidebar-first {
  -webkit-order: 1;
          order: 1;
  padding-right: 4rem;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto; }
@media (min-width: 1000px) {
    .layout-sidebar-first {
      -webkit-flex: 0 1 24rem;
              flex: 0 1 24rem; } }
@media (max-width: 999.5px) {
  main[role="main"].no-page-header .layout-sidebar-first {
    padding-right: 0;
    padding-top: 10rem; } }
main[role="main"].no-page-header .layout-sidebar-first > div {
  height: 100%; }
#block-upwing-footer {
  margin-bottom: 2rem; }
@media (min-width: 800px) {
    #block-upwing-footer {
      margin-bottom: 0; } }
#block-upwing-footer > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
#block-upwing-footer > ul li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block; }
#block-upwing-footer > ul li {
      text-transform: uppercase;
      margin-right: 2rem; }
#block-mainnavigation.menu-active h2 {
  color: transparent; }
#block-mainnavigation.menu-active h2:after {

    background-size: contain; }
#block-mainnavigation h2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  margin: 0;
  padding: 0 1rem;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 2.1rem;
  height: 3rem;
  line-height: 3rem;
  text-align: right;
  position: relative;
  z-index: 999; }
#block-mainnavigation h2:after {
    content: "";
    display: inline-block;
    margin-left: 1.5rem;
    height: 3rem;
    width: 3rem;
    vertical-align: bottom;
 }
@media (max-width: 359.5px) {
    #block-mainnavigation h2 {
      padding: 0;
      overflow: hidden;
      text-indent: -999rem;
      width: 3rem; } }
@media (min-width: 600px) {
    #block-mainnavigation h2 {
      font-size: 2.4rem; } }
@media (min-width: 800px) {
    #block-mainnavigation h2 {
      display: none; } }
#block-mainnavigation > ul.menu-active {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: none;
          transform: none;
  z-index: 998; }
#block-mainnavigation > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding-top: 10rem;
  position: fixed;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #002b54;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
#block-mainnavigation > ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
@media (min-width: 800px) {
    #block-mainnavigation > ul {
      pointer-events: auto;
      opacity: 1;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      position: static;
      padding: 0;
      width: auto;
      height: auto;
      background: transparent;
      -webkit-transform: none;
              transform: none;
      overflow: visible; } }
#block-mainnavigation > ul a {
    font-size: 2.1rem;
    color: #fff;
    display: block;
    padding: 1rem;
    text-transform: uppercase; }
#block-mainnavigation > ul a.nolink {
      cursor: default; }
@media (min-width: 800px) {
      #block-mainnavigation > ul a {
        color: #6f6f6f;
        font-size: 1.5rem;
        font-weight: 300;
        padding: .5rem 1rem;
        transition: all .25s ease; }
        #block-mainnavigation > ul a:hover {
          background: #00aeef;
          color: #fff; } }
@media (min-width: 1000px) {
      #block-mainnavigation > ul a {
        font-size: 1.65rem;
        padding: .5rem 1.5rem; } }
@media (min-width: 800px) {
    #block-mainnavigation > ul > li:first-child {
      display: none; } }
#block-mainnavigation > ul > li:hover > a,
  #block-mainnavigation > ul > li:active > a,
  #block-mainnavigation > ul > li:focus > a {
    background: #00aeef;
    color: #fff; }
@media (min-width: 800px) {
    #block-mainnavigation > ul > li:hover .menu,
    #block-mainnavigation > ul > li:active .menu,
    #block-mainnavigation > ul > li:focus .menu {
      opacity: 1;
      pointer-events: auto; } }
#block-mainnavigation > ul li {
    width: 100%;
    display: block;
    border-bottom: 0.1rem solid #777;
    position: relative; }
@media (min-width: 800px) {
      #block-mainnavigation > ul li {
        border: none;
        width: auto; } }
#block-mainnavigation > ul > .menu-item--active-trail {
    position: relative; }
@media (min-width: 800px) {
      #block-mainnavigation > ul > .menu-item--active-trail:before {
        content: "";
        display: block;
        width: 100%;
        height: .2rem;
        position: absolute;
        bottom: 0;
        background: #00aeef;
        pointer-events: none; } }
@media (min-width: 800px) {
    #block-mainnavigation > ul li.menu-item--expanded > a:after {
      content: "";
      display: inline-block;
      height: 0;
      width: 0;
      border: .6rem solid transparent;
      border-top-color: #00aeef;
      margin-left: 1rem;
      -webkit-transform: translateY(0.3rem);
              transform: translateY(0.3rem);
      transition: border-color .25s ease; }
    #block-mainnavigation > ul li.menu-item--expanded:hover > a:after,
    #block-mainnavigation > ul li.menu-item--expanded:active > a:after,
    #block-mainnavigation > ul li.menu-item--expanded:focus > a:after {
      border-top-color: #fff; } }
#block-mainnavigation > ul ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    background: #006497; }
#block-mainnavigation > ul ul li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
@media (min-width: 800px) {
      #block-mainnavigation > ul ul {
        opacity: 0;
        pointer-events: none;
        will-change: opacity;
        transition: all .25s ease;
        position: absolute;
        z-index: 99;
        top: 100%;
        right: 0; } }
#block-mainnavigation > ul ul li {
      border: none; }
#block-mainnavigation > ul ul li a {
        color: #fff;
        font-size: 1.8rem;
        padding: 1rem 2.5rem; }
@media (min-width: 800px) {
          #block-mainnavigation > ul ul li a {
            white-space: nowrap;
            font-size: 1.5rem;
            padding: .75rem 1.5rem;
            text-align: right; } }
#block-mainnavigation > ul ul li a:hover, #block-mainnavigation > ul ul li a:active {
          background: #00aeef; }
@media (max-width: 799.5px) {
  #block-mainnavigation .contextual {
    display: none !important; } }
body.menu-active {
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: 100vw; }
.logged-in #block-mainnavigation > ul {
  top: 39px;
  height: calc(100% - 39px); }
.layout-sidebar-first #block-innovations ul.menu li:first-child,
.layout-sidebar-first #block-onshoreproduction ul.menu li:first-child {
  display: none; }
.layout-sidebar-first ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  display: none; }
.layout-sidebar-first ul.menu li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
@media (min-width: 1000px) {
    .layout-sidebar-first ul.menu {
      display: block;
      margin-top: 17rem; } }
.layout-sidebar-first ul.menu a {
    display: block;
    padding: 1rem 0;
    color: #6f6f6f;
    transition: color .25s ease; }
.layout-sidebar-first ul.menu a:hover {
      color: #00aeef; }
.layout-sidebar-first ul.menu a.is-active {
      font-weight: bold;
      color: #00aeef; }
.layout-sidebar-first nav select {
  width: 100%;
  border: 0.1rem solid #d8e3e9;
  margin-top: 4rem;
  font-family: "Roboto", sans-serif;
  color: #6f6f6f;
  font-size: 1.6rem;
  padding: .5rem; }
@media (min-width: 1000px) {
    .layout-sidebar-first nav select {
      display: none; } }
.carousel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap; }
@media (min-width: 800px) {
    .carousel {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap; } }
.carousel__items {
    width: 100%; }
@media (min-width: 800px) {
      .carousel__items {
        margin-right: 5rem;
        width: 25rem; } }
@media (min-width: 1000px) {
      .carousel__items {
        width: 30rem; } }
@media (min-width: 1200px) {
      .carousel__items {
        width: 40rem; } }
@media (min-width: 800px) {
    .carousel__item {
      width: 25rem; } }
@media (min-width: 1000px) {
    .carousel__item {
      width: 30rem; } }
@media (min-width: 1200px) {
    .carousel__item {
      width: 40rem; } }
.carousel__image {
    width: 100%;
    text-align: center; }
.carousel__image img {
      margin: auto; }
.carousel__caption {
    text-align: center;
    width: 100%;
    min-height: 5rem; }
.carousel__caption p {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 500;
      font-size: 1.6rem; }
.carousel__caption p small {
        display: block;
        font-style: italic;
        font-size: 1.3rem; }
.carousel__pager {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 2rem; }
.carousel__pager li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block; }
.carousel__pager-item {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0 .5rem !important; }
.carousel__pager-item a {
        display: block;
        border: 0.1rem solid #000;
        background: #fff;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        text-indent: -999rem;
        overflow: hidden; }
.carousel__pager-item--active a {
        background: #0194d3; }
@media (min-width: 800px) {
    .carousel__text {
      -webkit-align-self: center;
              align-self: center; }
      .carousel__text p {
        max-width: 48rem; } }
.paragraph--type--header-image {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 100%; }
.paragraph--type--header-image .header-image {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center top;
    height: 30vw;
    min-height: 12rem;
    transition: min-height .25s ease; }
@media (min-width: 600px) {
      .paragraph--type--header-image .header-image {
        min-height: 15rem; } }
@media (min-width: 800px) {
      .paragraph--type--header-image .header-image {
        min-height: 20rem; } }
@media (min-width: 1000px) {
      .paragraph--type--header-image .header-image {
        min-height: 30rem; } }
@media (min-width: 1200px) {
      .paragraph--type--header-image .header-image {
        min-height: 40rem;
        background-attachment: fixed; } }
@media (min-width: 1600px) {
      .paragraph--type--header-image .header-image {
        min-height: 50rem;
        max-height: 70rem; } }
.paragraph--type--header-image .header-image:before, .paragraph--type--header-image .header-image:after {
      content: "";
      display: block;
      opacity: 0.5;
      position: absolute;
      top: 0; }
.paragraph--type--header-image .header-image:before {
      left: 0;
      width: 90%;
      padding-bottom: calc(90% / 3.32);

      background-size: contain;
      -webkit-transform: rotate(180deg) scale3d(-1, 1, 1);
              transform: rotate(180deg) scale3d(-1, 1, 1); }
.paragraph--type--header-image .header-image:after {
      right: 0;
      width: 52%;
      padding-bottom: calc(52% / 3.58);

      background-size: contain;
      -webkit-transform: rotate(180deg) scale3d(-1, 1, 1);
              transform: rotate(180deg) scale3d(-1, 1, 1); }
.paragraph--type--header-image .header-image + .page-title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    position: absolute;
    top: 30%;
    color: #fff;
    font-weight: 300;
    font-size: 2.6rem;
    line-height: 1.2;
    text-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1); }
@media (min-width: 800px) {
      .paragraph--type--header-image .header-image + .page-title {
        font-size: 3.6rem; } }
@media (min-width: 1000px) {
      .paragraph--type--header-image .header-image + .page-title {
        font-size: 5.6rem; } }
@media (min-width: 1200px) {
      .paragraph--type--header-image .header-image + .page-title {
        padding-left: 5%; } }
#block-upwing-content .node__content .timeline {
  position: relative;
  margin-top: -0.2rem;
  -webkit-transform: translateX(0.05rem);
          transform: translateX(0.05rem);
  overflow: hidden;
  margin-bottom: 5rem; }
#block-upwing-content .node__content .timeline:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - .1rem);
    height: 100%;
    width: .1rem;
    background: #0194d3; }
@media (min-width: 1200px) {
      #block-upwing-content .node__content .timeline:before {
        width: .2rem; } }
#block-upwing-content .node__content .timeline__item {
    width: 50%;
    margin-bottom: 5rem; }
@media (min-width: 800px) {
      #block-upwing-content .node__content .timeline__item {
        margin-bottom: 10rem; } }
#block-upwing-content .node__content .timeline__item:nth-child(2) {
      margin-top: 8vw; }
#block-upwing-content .node__content .timeline__item--active .timeline__item-content {
      opacity: 1;
      -webkit-transform: none;
              transform: none; }
#block-upwing-content .node__content .timeline__item-content {
    opacity: 0;
    -webkit-transform: translateY(5rem);
            transform: translateY(5rem);
    transition: all .5s ease; }
#block-upwing-content .node__content .timeline__title {
    color: #0194d3;
    font-weight: 700;
    font-size: 1.8rem;
    margin: 0;
    border-bottom: 0.1rem solid #0194d3;
    padding: 1.5rem 5rem .5rem 2rem; }
@media (min-width: 800px) {
      #block-upwing-content .node__content .timeline__title {
        font-size: 2.4rem; } }
@media (min-width: 1000px) {
      #block-upwing-content .node__content .timeline__title {
        font-size: 3rem;
        padding: 1.5rem 12rem .5rem 10rem; } }
@media (min-width: 1200px) {
      #block-upwing-content .node__content .timeline__title {
        border-width: .2rem; } }
#block-upwing-content .node__content .timeline__text {
    padding: 1.5rem 5rem 1.5rem 2rem;
    margin: 0;
    font-size: 1.7rem; }
@media (min-width: 1000px) {
      #block-upwing-content .node__content .timeline__text {
        padding: 1.5rem 12rem 1.5rem 10rem; } }
.node--type-about-page .layout-container:before {
  display: none; }
.node--type-about-page main[role="main"].no-page-header [class^="layout-"] {
  padding: 0; }
.node--type-about-page #block-upwing-page-title {
  display: none; }
.node--type-about-page #block-upwing-content .node__content {
  text-align: center;
  color: #65666a; }
@media (min-width: 1000px) {
    .node--type-about-page #block-upwing-content .node__content {
      max-width: 102rem;
      margin-left: auto;
      margin-right: auto; } }
.node--type-about-page #block-upwing-content .node__content h1 {
    color: #0194d3; }
.node--type-about-page #block-upwing-content .node__content .intro {
    padding-left: 8%;
    padding-right: 8%;
    line-height: 1.2; }
.node--type-about-page #block-upwing-content .node__content .intro:after {
      margin-left: auto;
      margin-right: auto; }
.node--type-about-page #block-upwing-content .node__hero {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-top: 0;
  margin-bottom: 3rem;
  position: relative; }
@media (min-width: 800px) {
    .node--type-about-page #block-upwing-content .node__hero {
      margin-bottom: 10rem;
      margin-top: -3vw; } }
@media (min-width: 1600px) {
    .node--type-about-page #block-upwing-content .node__hero {
      margin-top: -10vw; } }
.node--type-about-page #block-upwing-content .node__hero:before, .node--type-about-page #block-upwing-content .node__hero:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: calc(100% - 10rem); }
@media (max-width: 799.5px) {
      .node--type-about-page #block-upwing-content .node__hero:before, .node--type-about-page #block-upwing-content .node__hero:after {
        display: none; } }
.node--type-about-page #block-upwing-content .node__hero:before {
    left: 10%;
    width: 90%;

    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: calc(90% / 3.32);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
.node--type-about-page #block-upwing-content .node__hero:after {
    right: 48%;
    width: 52%;

    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: calc(52% / 3.58);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
.node--type-about-page #block-upwing-content .node__hero img {
    width: 100%;
    max-width: 100%;
    height: auto; }
@media (max-width: 799.5px) {
  .node--type-about-page #block-upwing-content .node__bottom {
    padding: 0 2rem; } }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 4rem 0 1.5rem; }
@media (min-width: 800px) {
    .node--type-about-page #block-upwing-content .node__bottom .bottom-item {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
      margin: 8rem 0 3rem; } }
@media (min-width: 800px) {
    .node--type-about-page #block-upwing-content .node__bottom .bottom-item:nth-child(even) {
      -webkit-flex-flow: row-reverse nowrap;
              flex-flow: row-reverse nowrap; } }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item__image {
    overflow: hidden;
    width: 80%;
    max-width: 40rem;
    margin: 0 auto; }
@media (min-width: 800px) {
      .node--type-about-page #block-upwing-content .node__bottom .bottom-item__image {
        -webkit-flex: 0 0 40%;
                flex: 0 0 40%;
        height: 0;
        padding-bottom: 40%;
        margin: 0; } }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item__image img {
      border-radius: 50%; }
@media (min-width: 800px) {
    .node--type-about-page #block-upwing-content .node__bottom .bottom-item__content {
      -webkit-flex: 0 0 50%;
              flex: 0 0 50%;
      padding-right: 8%; } }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0194d3;
    font-size: 2.1rem;
    font-weight: 500;
    margin-top: 0; }
@media (min-width: 800px) {
      .node--type-about-page #block-upwing-content .node__bottom .bottom-item__title {
        font-size: 2.4rem; } }
@media (min-width: 1200px) {
      .node--type-about-page #block-upwing-content .node__bottom .bottom-item__title {
        font-size: 3rem; } }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item__text {
    font-size: 1.6rem; }
.node--type-about-page #block-upwing-content .node__bottom .bottom-item__text a {
      color: #00aeef; }
.js .path-whatwereabout .node__bottom .bottom-item {
  opacity: 0;
  -webkit-transform: translateY(5rem);
          transform: translateY(5rem);
  transition: all .25s ease-in; }
.js .path-whatwereabout .node__bottom .bottom-item.in-view {
    opacity: 1;
    -webkit-transform: none;
            transform: none; }
.node--type-article #block-upwing-page-title h1 {
  font-size: 3.3rem; }
@media (min-width: 800px) {
  .node--type-article .layout-content {
    margin-right: 25%; } }
.node--type-careers-page .layout-container:before {
  display: none; }
.node--type-careers-page main[role="main"].no-page-header .layout-content {
  padding-top: 0;
  max-width: 120rem; }
.node--type-careers-page #block-upwing-page-title {
  text-align: center; }
.node--type-careers-page #block-upwing-content .node__content {
  overflow: visible; }
.node--type-careers-page .node__body {
  text-align: center;
  max-width: 86rem;
  margin: 0 auto 5rem; }
.node--type-careers-page .node__body h3 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -.025rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4; }
@media (min-width: 800px) {
      .node--type-careers-page .node__body h3 {
        font-size: 1.9rem; } }
@media (min-width: 1200px) {
      .node--type-careers-page .node__body h3 {
        font-size: 2.4rem; } }
.node--type-careers-page #block-upwing-content .careers__hero {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  height: 0;
  background-size: cover;
  background-position: center center;
  padding-bottom: calc(100% / 2.5);
  margin-bottom: 5rem; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers__hero {
      margin-bottom: 0; } }
@media (min-width: 1000px) {
    .node--type-careers-page #block-upwing-content .careers__hero {
      background-attachment: fixed; } }
.node--type-careers-page #block-upwing-content .careers__highlights {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding-bottom: 5rem; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers__highlights {
      margin-top: -9.5rem;
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
.node--type-careers-page #block-upwing-content .careers-highlights__item {
  text-align: center; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers-highlights__item {
      width: 32%; } }
.node--type-careers-page #block-upwing-content .careers-highlights__heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: #0194d3;
  font-size: 1.7rem;
  letter-spacing: -.025rem;
  margin: 2rem 0 1rem;
  padding: 0 10%; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers-highlights__heading {
      font-size: 2.2rem; } }
@media (min-width: 1200px) {
    .node--type-careers-page #block-upwing-content .careers-highlights__heading {
      font-size: 2.5rem; } }
.node--type-careers-page #block-upwing-content .careers__benefits {
  position: relative;
  padding: 2rem 0; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers__benefits {
      padding: 5rem 0;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
.node--type-careers-page #block-upwing-content .careers__benefits h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    font-weight: 100;
    font-size: 3rem;
    margin: 0 0 2rem;
    padding: 0;
    width: 100%; }
@media (min-width: 800px) {
      .node--type-careers-page #block-upwing-content .careers__benefits h2 {
        font-size: 4.5rem; } }
@media (min-width: 1200px) {
      .node--type-careers-page #block-upwing-content .careers__benefits h2 {
        font-size: 5.6rem; } }
.node--type-careers-page #block-upwing-content .careers__benefits h2 + p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
    letter-spacing: -.025rem;
    padding: 0 10%;
    margin: 0 0 3rem;
    width: 100%; }
@media (min-width: 800px) {
      .node--type-careers-page #block-upwing-content .careers__benefits h2 + p {
        font-size: 1.9rem; } }
@media (min-width: 1200px) {
      .node--type-careers-page #block-upwing-content .careers__benefits h2 + p {
        font-size: 2.4rem; } }
.node--type-careers-page #block-upwing-content .careers__benefits > div {
    width: 100%; }
.node--type-careers-page #block-upwing-content .careers__benefits > div h3 {
      color: #0194d3;
      font-weight: 700;
      font-size: 1.6rem; }
@media (min-width: 800px) {
        .node--type-careers-page #block-upwing-content .careers__benefits > div h3 {
          font-size: 1.8rem; } }
@media (min-width: 1200px) {
        .node--type-careers-page #block-upwing-content .careers__benefits > div h3 {
          font-size: 2.4rem; } }
.node--type-careers-page #block-upwing-content .careers__benefits > div p {
      font-size: 1.5rem; }
@media (min-width: 800px) {
        .node--type-careers-page #block-upwing-content .careers__benefits > div p {
          font-size: 1.6rem; } }
@media (min-width: 1200px) {
        .node--type-careers-page #block-upwing-content .careers__benefits > div p {
          font-size: 1.9rem; } }
.node--type-careers-page #block-upwing-content .careers__join, .node--type-careers-page #block-upwing-content .careers__contact {
  text-align: center;
  padding: 5rem 0; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers__join, .node--type-careers-page #block-upwing-content .careers__contact {
      padding: 8rem 0; } }
.node--type-careers-page #block-upwing-content .careers__join h2, .node--type-careers-page #block-upwing-content .careers__contact h2 {
    font-weight: 100;
    font-size: 3rem; }
@media (min-width: 800px) {
      .node--type-careers-page #block-upwing-content .careers__join h2, .node--type-careers-page #block-upwing-content .careers__contact h2 {
        font-size: 4.4rem; } }
@media (min-width: 1200px) {
      .node--type-careers-page #block-upwing-content .careers__join h2, .node--type-careers-page #block-upwing-content .careers__contact h2 {
        font-size: 5.6rem; } }
.node--type-careers-page #block-upwing-content .careers__join p, .node--type-careers-page #block-upwing-content .careers__contact p {
    font-size: 1.6rem; }
@media (min-width: 800px) {
      .node--type-careers-page #block-upwing-content .careers__join p, .node--type-careers-page #block-upwing-content .careers__contact p {
        font-size: 1.9rem; } }
@media (min-width: 1200px) {
      .node--type-careers-page #block-upwing-content .careers__join p, .node--type-careers-page #block-upwing-content .careers__contact p {
        font-size: 2.4rem; } }
.node--type-careers-page #block-upwing-content .careers__contact {
  position: relative; }
.node--type-careers-page #block-upwing-content .careers__contact > * {
    max-width: 86rem;
    margin-left: auto;
    margin-right: auto; }
.node--type-careers-page #block-upwing-content .careers__contact:before {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #002b54;
    z-index: -1; }
.node--type-careers-page #block-upwing-content .careers__contact p {
    color: #fff; }
.node--type-careers-page #block-upwing-content .careers__join {
  position: relative;
  padding: 2rem 0 !important; }
@media (min-width: 800px) {
    .node--type-careers-page #block-upwing-content .careers__join {
      padding: 2rem 0 !important; } }
.node--type-careers-page #block-upwing-content .careers__join h2,
  .node--type-careers-page #block-upwing-content .careers__join h3 {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto; }
.node--type-careers-page #block-upwing-content .careers__join h2 {
    margin-bottom: 10px; }
.node--type-careers-page #block-upwing-content .careers__join h3 {
    webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: -.025rem; }
.node--type-careers-page #block-upwing-content .careers__join h4 {
    color: #6f6f6f !important;
    font-size: 18px !important;
    font-weight: 500 !important; }
.node--type-careers-page #block-upwing-content .careers__join:before {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d8e3e9;
    z-index: -1; }
.node--type-careers-page footer {
  margin-top: 0; }
body[class*='path-contact'] #block-upwing-page-title h1 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #00aeef;
  font-weight: 100;
  font-size: 3rem; }
@media (min-width: 800px) {
    body[class*='path-contact'] #block-upwing-page-title h1 {
      font-size: 5.6rem; } }
body[class*='path-contact'] input.webform-button--submit {
  background-color: #00aeef;
  color: #fff;
  text-transform: none;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0);
  transition: all .15s ease; }
body[class*='path-contact'] input.webform-button--submit:hover {
    background: #0194d3;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25); }
body[class*='path-contact'] #block-contactbottom {
  display: block;
  background: #002b54;
  margin-top: 5rem; }
body[class*='path-contact'] #block-contactbottom > div {
    padding: 5rem 3rem; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom > div {
        padding: 5rem; } }
body[class*='path-contact'] #block-contactbottom h3 {
    color: #0194d3;
    font-size: 1.8rem;
    margin: 0 0 2rem; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom h3 {
        font-size: 2rem; } }
@media (min-width: 1200px) {
      body[class*='path-contact'] #block-contactbottom h3 {
        font-size: 2.4rem; } }
body[class*='path-contact'] #block-contactbottom p {
    color: #fff;
    font-size: 1.3rem; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom p {
        font-size: 1.4rem; } }
@media (min-width: 1200px) {
      body[class*='path-contact'] #block-contactbottom p {
        font-size: 1.6rem; } }
body[class*='path-contact'] #block-contactbottom p:first-of-type {
      margin-top: 0; }
body[class*='path-contact'] #block-contactbottom p:last-of-type {
      margin-bottom: 0; }
body[class*='path-contact'] #block-contactbottom a {
    color: #0194d3; }
body[class*='path-contact'] #block-contactbottom .wrap {
    max-width: 92rem;
    margin: 0 auto; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom .wrap {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap; } }
body[class*='path-contact'] #block-contactbottom #footer-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-bottom: 4rem; }
body[class*='path-contact'] #block-contactbottom #footer-contact > div {
      width: 50%; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom #footer-contact {
        width: calc(100% - 32rem);
        margin-bottom: 0; } }
body[class*='path-contact'] #block-contactbottom #footer-map {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 50%; }
@media (min-width: 800px) {
      body[class*='path-contact'] #block-contactbottom #footer-map {
        width: 32rem;
        padding-bottom: 30%; } }
@media (min-width: 1200px) {
      body[class*='path-contact'] #block-contactbottom #footer-map {
        padding-bottom: 25%; } }
body[class*='path-contact'] #block-contactbottom #footer-map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
body[class*='path-contact'] main[role="main"]:before {
  display: none; }
body[class*='path-contact'] #block-upwing-page-title {
  text-align: center; }
body[class*='path-contact'] .webform-confirmation__message {
  text-align: center;
  font-size: 1.4rem;
  max-width: 70rem;
  margin: 0 auto; }
body[class*='path-contact'] .webform-confirmation__back {
  display: none; }
body[class*='path-contact'] footer[role="contentinfo"] {
  margin-top: 0; }
#webform-submission-contact-add-form {
  margin: 0 auto;
  width: 100%;
  max-width: 450px; }
#webform-submission-contact-add-form .form-item {
    width: 100%;
    max-width: 450px;
    margin-bottom: 20px; }
#webform-submission-contact-add-form .form-item input, #webform-submission-contact-add-form .form-item select, #webform-submission-contact-add-form .form-item textarea {
      width: 100%; }
#webform-submission-contact-add-form .form-item input[type="checkbox"],
    #webform-submission-contact-add-form .form-item input[type="radio"] {
      width: auto; }
#webform-submission-contact-add-form > .form-item-website,
  #webform-submission-contact-add-form > .form-item-description {
    width: 100%; }
.node--type-event #block-upwing-content .node__content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap; }
@media (min-width: 800px) {
    .node--type-event #block-upwing-content .node__content {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
      overflow: visible; } }
.node--type-event #block-upwing-content .node__details {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  min-width: 100%;
  background: #0194d3;
  color: #fff;
  padding: 2rem; }
.node--type-event #block-upwing-content .node__details > div {
    margin: 0 auto;
    max-width: 100rem; }
@media (min-width: 800px) {
      .node--type-event #block-upwing-content .node__details > div > * {
        max-width: 50%; } }
.node--type-event #block-upwing-content .node__details h3 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    font-size: 3.2rem;
    letter-spacing: -0.05rem;
    margin: 0; }
.node--type-event #block-upwing-content .node__details h3 + p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2.2rem;
    margin: 0; }
.node--type-event #block-upwing-content .node__body img {
  margin-top: 3rem;
  max-width: 50%; }
.node--type-event #block-upwing-content .node__body h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0194d3;
  font-size: 2rem;
  font-weight: 500;
  margin: 0; }
@media (min-width: 800px) {
  .node--type-event #block-upwing-content .node__body {
    width: 50%;
    margin-right: 5rem; } }
.node--type-event #block-upwing-content .node__form {
  background: #f4f6f8;
  padding: 0 2rem 2rem; }
@media (min-width: 800px) {
    .node--type-event #block-upwing-content .node__form {
      width: 50%;
      position: relative; } }
@media (min-width: 800px) {
    .node--type-event #block-upwing-content .node__form:before {
      content: "";
      position: absolute;
      top: -5rem;
      left: 0;
      width: 100%;
      height: 5rem;
      background: #f4f6f8; } }
.node--type-event #block-upwing-content .node__form form .form-item {
    margin-bottom: 2rem; }
.node--type-event #block-upwing-content .node__form form label {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    margin-bottom: .5rem;
    color: #65666a;
    font-size: 1.6rem;
    font-weight: 700; }
.node--type-event #block-upwing-content .node__form form input {
    width: 100%;
    max-width: 100%; }
.node--type-event #block-upwing-content .node__form form input.button {
      display: table;
      max-width: 22rem;
      margin: 3rem auto 0;
      cursor: pointer;
      color: #fff;
      font-weight: 700;
      text-transform: none;
      border: none;
      background-color: #0194d3;
      transition: all .25s ease; }
.node--type-event #block-upwing-content .node__form form input.button:hover {
        background: #00aeef; }
.node--type-event #block-upwing-content .node__form form .js-form-type-webform-markup {
    margin-bottom: 3rem;
    text-align: center; }
.node--type-event #block-upwing-content .node__form form .js-form-type-webform-markup h2 {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 300;
      font-size: 3.8rem;
      margin: 0;
      padding: 0; }
@media (min-width: 1000px) {
        .node--type-event #block-upwing-content .node__form form .js-form-type-webform-markup h2 {
          font-size: 4.2rem; } }
.node--type-event #block-upwing-content .node__form form .js-form-type-webform-markup p {
      font-size: 1.8rem;
      margin: 0;
      padding: 0 10%; }
@media (min-width: 1000px) {
        .node--type-event #block-upwing-content .node__form form .js-form-type-webform-markup p {
          font-size: 2.2rem; } }
.node--type-event #block-mainnavigation,
.node--type-event footer[role='contentinfo'] {
  display: none; }
#block-upwing-content .node__content .case-study-triptych {
  border: 0.1rem solid #d8e3e9;
  position: relative;
  width: 100%;
  margin-bottom: 5rem; }
@media (min-width: 800px) {
    #block-upwing-content .node__content .case-study-triptych {
      padding-right: 50%; } }
#block-upwing-content .node__content .case-study-triptych h3 {
    text-transform: uppercase;
    color: #0194d3;
    font-weight: 500;
    font-size: 2rem;
    margin: 0 0 1rem; }
#block-upwing-content .node__content .case-study-triptych p {
    font-size: 1.6rem;
    margin: 0 0 1.5rem; }
#block-upwing-content .node__content .case-study-triptych p:last-child {
      margin-bottom: 0; }
@media (min-width: 800px) {
    #block-upwing-content .node__content .case-study-triptych > div {
      border-right: 0.1rem solid #d8e3e9; } }
#block-upwing-content .node__content .case-study-triptych > div > div {
    border: 1.5rem solid #fff;
    background: #f4f7f9;
    padding: 5rem; }
#block-upwing-content .node__content .case-study-triptych__challenge {
    border-bottom: 0.1rem solid #d8e3e9; }
@media (max-width: 799.5px) {
    #block-upwing-content .node__content .case-study-triptych__solution {
      border-bottom: 0.1rem solid #d8e3e9; } }
@media (min-width: 800px) {
    #block-upwing-content .node__content .case-study-triptych__results {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100% !important;
      border: none !important; } }
#block-upwing-content .node__content .case-study-triptych__results > div {
    height: 100%;
    background: #e7eef2 !important; }
#block-upwing-content .node__content table td img {
  width: 80%; }
#block-upwing-content .node__content ol {
  list-style: decimal;
  padding: 5rem 7rem;
  background: #e7eef2; }
#block-upwing-content .node__content ol li {
    display: list-item;
    list-style: decimal;
    padding-left: 0;
    margin-bottom: 2rem;
    font-size: 1.6rem; }
#block-upwing-content .node__content ol li:last-child {
      margin-bottom: 0; }
#block-upwing-content .node__content ol li:before {
      display: none;
      list-style: list-item; }
#block-upwing-content .node__content ol li strong {
      font-weight: 700;
      color: #6f6f6f;
      display: inline;
      margin-bottom: 0; }
body.front header {
  position: relative;
  z-index: 1;
  box-shadow: none; }
body.front #block-mainnavigation h2,
body.front #block-mainnavigation > ul a {
  color: #fff; }
body.front .no-page-header .layout-content {
  padding-top: 0; }
body.front main {
  background: transparent;
  margin-top: -10.8rem;
  padding-left: 0 !important;
  padding-right: 0 !important; }
body.front main:before {
    display: none; }
body.front .hero {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 0;
  position: relative;

  background-size: cover;
  min-height: 68vw;
  padding-top: 10rem;
  text-align: center; }
@media (min-width: 800px) {
    body.front .hero {
      text-align: left; } }
@media (min-width: 800px) {
    body.front .hero:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      width: 100%;
      padding-bottom: 47.466%;

      background-size: 100% 100%; } }
body.front .hero h1 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-weight: 100;
    text-align: right;
    font-size: 2.8rem;
    line-height: 1.4;
    margin: 0 3rem;
    max-width: 192rem; }
body.front .hero h1 em {
      font-size: 2.2rem; }
@media (min-width: 600px) {
      body.front .hero h1 {
        font-size: 4.2rem;
        margin: 0 5rem 0 0; }
        body.front .hero h1 em {
          font-size: 3rem; } }
@media (min-width: 1000px) {
      body.front .hero h1 {
        font-size: 4.5rem;
        padding-top: 2vw; }
        body.front .hero h1 em {
          font-size: 3.5rem; } }
@media (min-width: 1200px) {
      body.front .hero h1 {
        font-size: 5.6rem;
        padding-top: 4vw;
        margin-right: 5vw; }
        body.front .hero h1 em {
          font-size: 4.2rem; } }
@media (min-width: 1600px) {
      body.front .hero h1 {
        padding-top: 5vw;
        margin-right: 10vw; } }
body.front .hero img {
    margin: 0 auto 6vw;
    max-width: 90vw; }
@media (min-width: 800px) {
      body.front .hero img {
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        max-width: 80vw;
        padding-bottom: 19vw;
        width: calc(100% + 12rem);
        margin: 0; } }
body.front .hero h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background: #fff;
    padding: 3rem;
    max-width: 65rem; }
@media (min-width: 600px) {
      body.front .hero h2 {
        padding: 5rem; } }
@media (min-width: 800px) {
      body.front .hero h2 {
        background: transparent;
        position: absolute;
        bottom: 0;
        right: 5rem;
        width: 53%;
        padding: 0;
        padding-bottom: 12vw;
        font-size: 1.4rem;
        line-height: 1.4;
        text-align: right; } }
@media (min-width: 1000px) {
      body.front .hero h2 {
        font-size: 1.9rem;
        padding-bottom: 13vw; } }
@media (min-width: 1200px) {
      body.front .hero h2 {
        font-size: 2.3rem; } }
body.front .hero h2 strong {
      font-weight: 400; }
body.front .hero h2 strong span {
        color: #0194d3; }
body.front .hero ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background: #fff;
    padding: 0 3rem 3rem; }
body.front .hero ul li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
@media (min-width: 800px) {
      body.front .hero ul {
        padding: 0;
        background: transparent;
        position: absolute;
        bottom: 1vw;
        right: 5rem;
        width: 60%; } }
body.front .hero ul li {
      text-align: center; }
body.front .hero ul li:nth-child(2) {
        margin-left: 1.5rem;
        margin-right: 1.5rem; }
body.front .hero ul h3 {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
      font-weight: 300;
      font-size: 1.8rem; }
@media (min-width: 800px) {
        body.front .hero ul h3 {
          font-size: 2.3rem; } }
@media (min-width: 1000px) {
        body.front .hero ul h3 {
          font-size: 2.8rem; } }
body.front .hero ul a {
      display: block;
      color: #0194d3;
      transition: color .25s ease; }
body.front .hero ul a:hover {
        color: #00aeef; }
body.front .hero ul a span {
        display: block;
        color: #6f6f6f;
        font-size: 1.2rem;
        font-weight: 400; }
body.front .hero ul a span:after {
          content: "";
          background: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%2300aeef'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E");
          display: block;
          margin: auto;
          width: 1.7rem;
          height: 1.2rem;
          margin-top: .8rem; }
@media (min-width: 800px) {
          body.front .hero ul a span {
            font-size: 1.4rem; } }
@media (min-width: 1000px) {
          body.front .hero ul a span {
            font-size: 1.6rem; } }
body.front .center-text {
  padding: 3rem;
  text-align: center; }
@media (min-width: 800px) {
    body.front .center-text {
      padding: 5rem; } }
@media (min-width: 1000px) {
    body.front .center-text {
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw); }
      body.front .center-text.natural-gas {
 } }
@media (min-width: 1000px) and (min-width: 800px) {
      body.front .center-text.natural-gas {
        margin-top: 2rem; } }
body.front .center-text h2 {
    font-weight: 100;
    font-size: 3.8rem;
    line-height: 1.1;
    color: #00aeef;
    margin: 0; }
@media (min-width: 800px) {
      body.front .center-text h2 {
        font-size: 4.2rem;
        max-width: 78rem;
        margin-left: auto;
        margin-right: auto; } }
body.front .center-text h3 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2; }
@media (min-width: 800px) {
      body.front .center-text h3 {
        font-size: 2.2rem;
        margin-top: 1rem;
        max-width: 78rem;
        margin-left: auto;
        margin-right: auto; } }
body.front .center-text p {
    font-size: 1.6rem;
    margin-top: 2rem; }
@media (min-width: 800px) {
      body.front .center-text p {
        max-width: 78rem;
        margin-left: auto;
        margin-right: auto; } }
body.front .center-text a {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #00aeef; }
body.front .center-text a.button:hover {
      color: #fff; }
body.front .center-text.natural-gas {
  background-color: transparent;
  position: relative;
  z-index: 0; }
body.front .tour {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative; }
body.front .tour__images {
    min-height: 50rem;
    position: -webkit-sticky;
    position: sticky;
    z-index: 0;
    height: 100vh;
    width: 100%;
    top: 0; }
body.front .tour__image {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    height: auto;
    opacity: 0;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: opacity .5s ease; }
body.front .tour__image--active {
      opacity: 1; }
body.front .tour__cards {
    position: relative;
    z-index: 1; }
body.front .tour__card {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center; }
body.front .tour__card .card__content {
      -webkit-align-self: center;
              align-self: center;
      position: relative;
      z-index: 10;
      transition: .5s transform ease;
      -webkit-transform: scale(0.5);
              transform: scale(0.5); }
body.front .tour__card--active .card__content {
      -webkit-transform: none;
              transform: none; }
body.front .tour .card__content {
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    border-radius: .5rem;
    padding: 3rem;
    max-width: 80vw;
    margin: 0 auto;
    will-change: transform; }
@media (min-width: 800px) {
      body.front .tour .card__content {
        padding: 6rem;
        border-radius: 1rem;
        max-width: 64rem; } }
@media (min-width: 1000px) {
      body.front .tour .card__content {
        padding: 8rem; } }
body.front .tour .card__content h4, body.front .tour .card__content p {
      margin: 0;
      padding: 0; }
body.front .tour .card__content h4 {
      font-size: 2rem;
      margin-bottom: 3rem;
      font-weight: 700; }
body.front .tour .card__content p {
      font-size: 1.6rem;
      line-height: 1.8; }
body.front #before-after {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw); }
body.front #before-after #comparison {
    position: relative;
    max-width: 150rem;
    min-height: 10rem;
    margin-left: auto;
    margin-right: auto; }
body.front #before-after .twentytwenty-handle {
    cursor: -webkit-grab;
    cursor: grab;
    height: 7.2rem;
    width: 7.2rem;
    border-width: .6rem;
    box-shadow: none;
    margin-top: -4.2rem;
    margin-left: -4.2rem; }
body.front #before-after .twentytwenty-handle:before, body.front #before-after .twentytwenty-handle:after {
      width: .6rem;
      margin-left: -0.3rem;
      box-shadow: none; }
body.front #before-after .twentytwenty-handle:before {
      bottom: calc(100% - 2rem); }
body.front #before-after .twentytwenty-handle:after {
      top: calc(100% - 2rem); }
body.front #before-after .twentytwenty-left-arrow,
  body.front #before-after .twentytwenty-right-arrow,
  body.front #before-after .twentytwenty-up-arrow,
  body.front #before-after .twentytwenty-down-arrow {
    border: 0.8rem solid transparent; }
body.front #before-after .twentytwenty-left-arrow,
  body.front #before-after .twentytwenty-right-arrow {
    margin-top: -0.8rem; }
body.front #before-after .twentytwenty-left-arrow {
    border-right: 1.2rem solid white;
    margin-left: -2.8rem; }
body.front #before-after .twentytwenty-right-arrow {
    border-left: 1.2rem solid white;
    margin-right: -2.8rem; }
body.front #before-after .twentytwenty-overlay,
  body.front #before-after .twentytwenty-overlay:hover {
    background: transparent; }
body.front #before-after .twentytwenty-before-label,
  body.front #before-after .twentytwenty-after-label {
    opacity: 1;
    border-radius: 0; }
body.front #before-after .twentytwenty-before-label:before, body.front #before-after .twentytwenty-before-label:after,
    body.front #before-after .twentytwenty-after-label:before,
    body.front #before-after .twentytwenty-after-label:after {
      color: #65666a;
      background: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      letter-spacing: .1rem;
      border-radius: 0;
      padding: 0 1.5rem;
      font-size: 1rem; }
@media (min-width: 360px) {
        body.front #before-after .twentytwenty-before-label:before, body.front #before-after .twentytwenty-before-label:after,
        body.front #before-after .twentytwenty-after-label:before,
        body.front #before-after .twentytwenty-after-label:after {
          padding: .2rem 2rem;
          font-size: 1.2rem; } }
@media (min-width: 800px) {
        body.front #before-after .twentytwenty-before-label:before, body.front #before-after .twentytwenty-before-label:after,
        body.front #before-after .twentytwenty-after-label:before,
        body.front #before-after .twentytwenty-after-label:after {
          padding: 1rem 3rem;
          font-size: 1.4rem; } }
body.front #before-after .twentytwenty-before-label:before {
    left: 0; }
body.front #before-after .twentytwenty-after-label:before {
    right: 0; }
body.front #before-after .description {
    padding: 3rem 5rem 0;
    max-width: 140rem;
    margin-left: auto;
    margin-right: auto; }
@media (min-width: 1000px) {
      body.front #before-after .description {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-top: 5rem; }
        body.front #before-after .description > div {
          -webkit-flex: 0 0 calc(50% - 2.5rem);
                  flex: 0 0 calc(50% - 2.5rem);
          padding-left: 12rem;
          position: relative; } }
body.front #before-after .description .before {
      margin-bottom: 6rem; }
@media (min-width: 800px) {
        body.front #before-after .description .before {
          margin-bottom: 0; } }
body.front #before-after .description h3 {
      color: #0194d3;
      font-weight: 400;
      font-size: 2.2rem;
      margin: 0; }
@media (min-width: 1000px) {
        body.front #before-after .description h3 {
          position: absolute;
          width: 9rem;
          left: 0; } }
body.front #before-after .description ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
body.front #before-after .description ul li {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block; }
body.front #before-after .description ul li {
        position: relative;
        font-size: 1.5rem;
        line-height: 1.3;
        margin: 0 0 1rem;
        padding-left: 3rem; }
body.front #before-after .description ul li:before {
          position: absolute;
          left: 0;
          content: "\2022"; }
body.front #before-after .description p {
      font-size: 1.8rem;
      border: 0.1rem solid #0194d3;
      border-left: 0;
      border-right: 0;
      padding: 2rem; }
body.front .video-wrapper {
  position: relative;
  max-width: 90rem;
  margin: 0 auto; }
body.front .video-wrapper:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -0.2rem;

    background-size: cover;
    width: 100vw;
    height: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
body.front .video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  cursor: pointer;
  margin-top: 8rem; }
body.front .video.active:after {
    display: none; }
body.front .video.active iframe {
    opacity: 1; }
body.front .video.oil {

    background-size: cover; }
body.front .video.oil:after {
      content: "";
      position: absolute;

      background-size: contain;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 6.3rem;
      height: 8rem; }
@media (min-width: 800px) {
        body.front .video.oil:after {
          width: 12.5rem;
          height: 16rem; } }
body.front .video iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0; }
body.front .features {
  text-align: center;
  margin: 3rem 5rem 6rem; }
@media (min-width: 1200px) {
    body.front .features {
      margin-bottom: 8rem;
      margin-left: 0;
      margin-right: 0; } }
body.front .features ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap; }
body.front .features ul li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
@media (min-width: 800px) {
      body.front .features ul {
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-justify-content: space-around;
                justify-content: space-around; } }
@media (min-width: 1200px) {
      body.front .features ul {
        margin-bottom: 5rem; } }
body.front .features ul li {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-bottom: 3rem; }
@media (min-width: 800px) {
        body.front .features ul li {
          width: 30%; }
          body.front .features ul li:nth-child(2) {
            margin-left: 5%;
            margin-right: 5%; } }
body.front .features h4 {
    font-size: 2rem;
    font-weight: bold;
    color: #0194d3;
    line-height: 1.4; }
body.front .features p {
    font-size: 1.6rem; }
body.front .features .button {
    display: inline-block; }
body.front .contact {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  background-size: 60% auto;
  position: relative;
  text-align: center;
  padding: 10rem 4rem;
  border-bottom: 2.4rem solid #74C0E2; }
@media (min-width: 600px) {
    body.front .contact {
      background-size: 45% auto; } }
body.front .contact__inner {
    max-width: 78rem;
    margin-left: auto;
    margin-right: auto; }
body.front .contact h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 4rem;
    font-weight: 100;
    color: #0194d3;
    margin: 0; }
body.front .contact p {
    font-size: 1.6rem;
    margin: 0 0 4rem; }
body.front footer[role="contentinfo"] {
  margin-top: 0; }
.path-innovations #block-upwing-content .node__content {
  margin-bottom: 5rem; }
@media (min-width: 800px) {
    .path-innovations #block-upwing-content .node__content {
      margin-bottom: 10rem; } }
@media (max-width: 599.5px) {
    .path-innovations #block-upwing-content .node__content .align-right {
      float: none;
      max-width: 20rem;
      margin: 0 calc(50% - 10rem) 3rem; } }
@media (min-width: 600px) {
    .path-innovations #block-upwing-content .node__content ol {
      max-width: 60%; } }
.path-innovations #block-upwing-content .node__content p.intro {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 3rem 0;
    width: 90%;
    max-width: 86rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
    line-height: 2.2rem; }
@media (min-width: 800px) {
      .path-innovations #block-upwing-content .node__content p.intro {
        padding: 5rem 0;
        line-height: 3.2rem;
        font-size: 2.4rem; } }
.path-innovations #block-upwing-content .node__content p.intro:after {
      margin-left: auto;
      margin-right: auto; }
body[class*='path-innovations-'] #block-upwing-page-title h1 {
  opacity: 0; }
.node--type-news-events-landing .layout-container:before, .path-news-and-events .layout-container:before {
  display: none; }
.node--type-news-events-landing main[role="main"].no-page-header [class^="layout-"], .path-news-and-events main[role="main"].no-page-header [class^="layout-"] {
  padding-top: 0; }
.node--type-news-events-landing .layout-content:last-child, .path-news-and-events .layout-content:last-child {
  max-width: none; }
.node--type-news-events-landing #block-upwing-page-title, .path-news-and-events #block-upwing-page-title {
  text-align: center; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-page-title, .path-news-and-events #block-upwing-page-title {
      margin-top: 5rem; } }
.node--type-news-events-landing #block-upwing-page-title h1:after, .path-news-and-events #block-upwing-page-title h1:after {
    content: "";
    display: block;
    width: 15rem;
    height: .1rem;
    background: #0194d3;
    margin: 1.5rem auto; }
@media (min-width: 800px) {
      .node--type-news-events-landing #block-upwing-page-title h1:after, .path-news-and-events #block-upwing-page-title h1:after {
        margin: 3rem auto; } }
.node--type-news-events-landing .node__content, .node--type-news-events-landing .view-id-news, .path-news-and-events .node__content, .path-news-and-events .view-id-news {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap; }
@media (min-width: 800px) {
    .node--type-news-events-landing .node__content, .node--type-news-events-landing .view-id-news, .path-news-and-events .node__content, .path-news-and-events .view-id-news {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
.node--type-news-events-landing .node__content a, .node--type-news-events-landing .view-id-news a, .path-news-and-events .node__content a, .path-news-and-events .view-id-news a {
    color: #0194d3; }
.node--type-news-events-landing .node__content .node__news, .node--type-news-events-landing .view-id-news .node__news, .path-news-and-events .node__content .node__news, .path-news-and-events .view-id-news .node__news {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto; }
@media (min-width: 800px) {
      .node--type-news-events-landing .node__content .node__news, .node--type-news-events-landing .view-id-news .node__news, .path-news-and-events .node__content .node__news, .path-news-and-events .view-id-news .node__news {
        -webkit-flex: 0 0 calc(65% - 8rem);
                flex: 0 0 calc(65% - 8rem); } }
.node--type-news-events-landing .node__content .node__events, .node--type-news-events-landing .view-id-news .node__events, .path-news-and-events .node__content .node__events, .path-news-and-events .view-id-news .node__events {
    padding: 5rem;
    background: #d8e3e9; }
@media (min-width: 800px) {
      .node--type-news-events-landing .node__content .node__events, .node--type-news-events-landing .view-id-news .node__events, .path-news-and-events .node__content .node__events, .path-news-and-events .view-id-news .node__events {
        -webkit-flex: 0 0 33%;
                flex: 0 0 33%; } }
.node--type-news-events-landing #block-upwing-content .node__content .views-row:not(:first-child), .node--type-news-events-landing #block-upwing-content .view-id-news .views-row:not(:first-child), .path-news-and-events #block-upwing-content .node__content .views-row:not(:first-child), .path-news-and-events #block-upwing-content .view-id-news .views-row:not(:first-child) {
  margin-top: 5rem; }
.node--type-news-events-landing #block-upwing-content .node__content .news-event + .news-event, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event + .news-event, .path-news-and-events #block-upwing-content .node__content .news-event + .news-event, .path-news-and-events #block-upwing-content .view-id-news .news-event + .news-event {
  margin-top: 5rem; }
.node--type-news-events-landing #block-upwing-content .node__content .news-event__date, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__date, .path-news-and-events #block-upwing-content .node__content .news-event__date, .path-news-and-events #block-upwing-content .view-id-news .news-event__date {
  color: #0194d3;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 0 1rem; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__date, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__date, .path-news-and-events #block-upwing-content .node__content .news-event__date, .path-news-and-events #block-upwing-content .view-id-news .news-event__date {
      font-size: 1.5rem; } }
@media (min-width: 1200px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__date, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__date, .path-news-and-events #block-upwing-content .node__content .news-event__date, .path-news-and-events #block-upwing-content .view-id-news .news-event__date {
      font-size: 2.1rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__title, .path-news-and-events #block-upwing-content .node__content .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .news-event__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: #0194d3;
  font-size: 1.8rem;
  line-height: 1.1;
  margin: 0; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__title, .path-news-and-events #block-upwing-content .node__content .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .news-event__title {
      font-size: 2.4rem; } }
@media (min-width: 1200px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__title, .path-news-and-events #block-upwing-content .node__content .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .news-event__title {
      font-size: 3rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .news-event__text, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__text, .path-news-and-events #block-upwing-content .node__content .news-event__text, .path-news-and-events #block-upwing-content .view-id-news .news-event__text {
  font-size: 1.2rem; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__text, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__text, .path-news-and-events #block-upwing-content .node__content .news-event__text, .path-news-and-events #block-upwing-content .view-id-news .news-event__text {
      font-size: 1.3rem; } }
@media (min-width: 1200px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__text, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__text, .path-news-and-events #block-upwing-content .node__content .news-event__text, .path-news-and-events #block-upwing-content .view-id-news .news-event__text {
      font-size: 1.6rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .news-event__link a, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__link a, .path-news-and-events #block-upwing-content .node__content .news-event__link a, .path-news-and-events #block-upwing-content .view-id-news .news-event__link a {
  font-weight: 400;
  color: #0194d3;
  font-size: 1.5rem; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__link a, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__link a, .path-news-and-events #block-upwing-content .node__content .news-event__link a, .path-news-and-events #block-upwing-content .view-id-news .news-event__link a {
      font-size: 1.6rem; } }
@media (min-width: 1200px) {
    .node--type-news-events-landing #block-upwing-content .node__content .news-event__link a, .node--type-news-events-landing #block-upwing-content .view-id-news .news-event__link a, .path-news-and-events #block-upwing-content .node__content .news-event__link a, .path-news-and-events #block-upwing-content .view-id-news .news-event__link a {
      font-size: 2rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .node__events, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events, .path-news-and-events #block-upwing-content .node__content .node__events, .path-news-and-events #block-upwing-content .view-id-news .node__events {
  margin-top: 5rem; }
@media (min-width: 800px) {
    .node--type-news-events-landing #block-upwing-content .node__content .node__events, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events, .path-news-and-events #block-upwing-content .node__content .node__events, .path-news-and-events #block-upwing-content .view-id-news .node__events {
      margin-top: 0; } }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .views-row + .views-row, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .views-row + .views-row, .path-news-and-events #block-upwing-content .node__content .node__events .views-row + .views-row, .path-news-and-events #block-upwing-content .view-id-news .node__events .views-row + .views-row {
    margin-top: 3rem; }
.node--type-news-events-landing #block-upwing-content .node__content .node__events h3, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events h3, .path-news-and-events #block-upwing-content .node__content .node__events h3, .path-news-and-events #block-upwing-content .view-id-news .node__events h3 {
    color: #0194d3;
    border-bottom: 0.1rem solid #0194d3;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    margin-top: 0;
    font-weight: 400;
    font-size: 1.6rem; }
@media (min-width: 800px) {
      .node--type-news-events-landing #block-upwing-content .node__content .node__events h3, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events h3, .path-news-and-events #block-upwing-content .node__content .node__events h3, .path-news-and-events #block-upwing-content .view-id-news .node__events h3 {
        font-size: 1.9rem; } }
@media (min-width: 1200px) {
      .node--type-news-events-landing #block-upwing-content .node__content .node__events h3, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events h3, .path-news-and-events #block-upwing-content .node__content .node__events h3, .path-news-and-events #block-upwing-content .view-id-news .node__events h3 {
        font-size: 2.4rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__date, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__date, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__date, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__date {
    margin-bottom: 0; }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__title, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem; }
@media (min-width: 800px) {
      .node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__title, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__title {
        font-size: 2rem; } }
@media (min-width: 1200px) {
      .node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__title, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__title, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__title, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__title {
        font-size: 2.5rem; } }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__text, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__text, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__text, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__text {
    margin-bottom: 1rem; }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__text p, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__text p, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__text p, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__text p {
      margin: 0; }
.node--type-news-events-landing #block-upwing-content .node__content .node__events .news-event__link a, .node--type-news-events-landing #block-upwing-content .view-id-news .node__events .news-event__link a, .path-news-and-events #block-upwing-content .node__content .node__events .news-event__link a, .path-news-and-events #block-upwing-content .view-id-news .node__events .news-event__link a {
    font-size: 1.5rem; }
.path-simulation #block-upwing-content .node__content h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  font-weight: 500; }
.path-simulation #block-upwing-content .node__content .input-data > h4 {
  margin-bottom: 1rem; }
.path-simulation #block-upwing-content .node__content .input-data__example {
  margin: 0 0 4rem; }
.path-simulation #block-upwing-content .node__content .input-data__example p {
    margin: 0;
    text-transform: lowercase;
    font-size: 1.4rem; }
.path-simulation #block-upwing-content .node__content .input-data__legend {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  border: 0.1rem solid #d8e3e9;
  border-left: none;
  border-right: none;
  padding: 1rem 0;
  margin: 0 0 4rem; }
.path-simulation #block-upwing-content .node__content .input-data__legend li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block; }
.path-simulation #block-upwing-content .node__content .input-data__legend li {
    margin-right: 5rem;
    line-height: 2.8rem;
    font-size: 1.4rem;
    width: 100%;
    margin-bottom: 2rem; }
.path-simulation #block-upwing-content .node__content .input-data__legend li:last-child {
      margin-bottom: 0; }
@media (min-width: 800px) {
      .path-simulation #block-upwing-content .node__content .input-data__legend li {
        width: auto;
        margin-bottom: 0; } }
.path-simulation #block-upwing-content .node__content .input-data__legend li:before {
      content: "";
      display: inline-block;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 2rem;
      vertical-align: bottom; }
.path-simulation #block-upwing-content .node__content .input-data__legend li.a-req:before {
      background: #D0021B; }
.path-simulation #block-upwing-content .node__content .input-data__legend li.req:before {
      background: #ff9600; }
.path-simulation #block-upwing-content .node__content .input-data__legend li.opt:before {
      background: #7ed321; }
.path-simulation #block-upwing-content .node__content .input-data__legend li.upwing:before {
      background: #0980b2; }
.path-simulation #block-upwing-content .node__content .input-data__table {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }
.path-simulation #block-upwing-content .node__content .input-data__table li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
.path-simulation #block-upwing-content .node__content .input-data__table > li {
    width: 100%;
    margin: 0 0 5rem; }
@media (min-width: 600px) {
      .path-simulation #block-upwing-content .node__content .input-data__table > li {
        width: 50%;
        padding-right: 3rem; } }
@media (min-width: 800px) {
      .path-simulation #block-upwing-content .node__content .input-data__table > li {
        width: 33.33%; } }
@media (min-width: 1200px) {
      .path-simulation #block-upwing-content .node__content .input-data__table > li {
        width: 16.66%;
        margin-bottom: 0; } }
.path-simulation #block-upwing-content .node__content .input-data__table > li img {
      margin: 0 0 0 2rem;
      width: auto; }
.path-simulation #block-upwing-content .node__content .input-data__table > li > div {
      border-left: 0.1rem solid #d8e3e9; }
.path-simulation #block-upwing-content .node__content .input-data__table > li h5 {
      padding: 0 0 2rem 2rem;
      margin: 2rem 0 0 0;
      color: #3c3c3c;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2rem; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul {
      padding: 0 0 0 1.5rem;
      border-left: 1rem solid transparent; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul + ul {
        margin-top: 4rem; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul.a-req {
        border-color: #D0021B; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul.req {
        border-color: #ff9600; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul.opt {
        border-color: #7ed321; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul.upwing {
        border-color: #0980b2; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul:last-of-type {
        padding-bottom: 0; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul li {
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 400;
        padding: 0 0 2rem 0; }
.path-simulation #block-upwing-content .node__content .input-data__table > li ul li:last-child {
          padding-bottom: 0; }
.path-simulation #block-upwing-content .node__content .input-data__table ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
.path-simulation #block-upwing-content .node__content .input-data__table ul li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
.path-simulation #block-upwing-content .node__content .input-data__table ul li:before {
      display: none; }
.path-simulation #block-upwing-content .node__content .output-data ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }
.path-simulation #block-upwing-content .node__content .output-data ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex; }
.path-simulation #block-upwing-content .node__content .output-data ul li {
    margin: 0 2.5rem 2.5rem 0;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap; }
.path-simulation #block-upwing-content .node__content .output-data ul li.production img {
      width: 8.3rem;
      height: 9.6rem; }
.path-simulation #block-upwing-content .node__content .output-data ul li.pressure img {
      width: 10.5rem;
      height: 9.5rem; }
.path-simulation #block-upwing-content .node__content .output-data ul li.compressor img {
      width: 10rem;
      height: 10rem; }
.path-simulation #block-upwing-content .node__content .output-data ul li.power img {
      width: 9.5rem;
      height: 9.5rem; }
.path-simulation #block-upwing-content .node__content .output-data ul li.forecast img {
      width: 9.6rem;
      height: 9.6rem; }
.path-simulation #block-upwing-content .node__content .output-data__img {
  width: 14rem;
  height: 14rem;
  background: #0194d3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center; }
.path-simulation #block-upwing-content .node__content .output-data__title {
  width: 14rem;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 500;
  color: #3c3c3c;
  margin: 1rem 0; }
@media (min-width: 1000px) {
    .path-simulation #block-upwing-content .node__content .output-data__title {
      font-size: 1.8rem; } }
.path-simulation #block-upwing-content .node__content .output-data__link {
  font-size: 1.4rem;
  display: block;
  margin-top: auto;
  text-transform: lowercase; }
.node--type-team-page .layout-content > div:last-child {
  transition: all .5s ease-out;
  -webkit-transform: translateY(5rem);
          transform: translateY(5rem); }
.node--type-team-page #block-upwing-page-title h1 {
  font-weight: 400;
  color: #0194d3;
  font-size: 3rem;
  margin-top: 4rem; }
@media (min-width: 800px) {
    .node--type-team-page #block-upwing-page-title h1 {
      font-size: 4.2rem;
      padding-left: 35%; } }
@media (min-width: 1200px) {
    .node--type-team-page #block-upwing-page-title h1 {
      font-size: 4.6rem; } }
.node--type-team-page .bio {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  margin-bottom: 3rem; }
@media (min-width: 800px) {
    .node--type-team-page .bio {
      -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap; } }
.node--type-team-page .bio__image {
    -webkit-flex: 0 0 35%;
            flex: 0 0 35%;
    text-align: center;
    margin-bottom: 2rem; }
@media (min-width: 800px) {
      .node--type-team-page .bio__image {
        padding-right: 5%;
        margin-bottom: 0;
        text-align: left; } }
.node--type-team-page .bio__image img {
      width: 100%;
      max-width: 18rem; }
@media (min-width: 600px) {
        .node--type-team-page .bio__image img {
          max-width: 22rem; } }
@media (min-width: 800px) {
        .node--type-team-page .bio__image img {
          max-width: 24.4rem; } }
.node--type-team-page .bio__text {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto; }
.node--type-team-page .bio__name {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.8rem;
    margin: 0;
    padding: 0;
    color: #65666a !important; }
@media (min-width: 800px) {
      .node--type-team-page .bio__name {
        font-size: 2rem; } }
@media (min-width: 1000px) {
      .node--type-team-page .bio__name {
        font-size: 2.4rem; } }
@media (min-width: 1200px) {
      .node--type-team-page .bio__name {
        font-size: 3rem; } }
.node--type-team-page .bio__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    color: #65666a;
    font-size: 1.6rem;
    margin: 0;
    padding: 0; }
@media (min-width: 800px) {
      .node--type-team-page .bio__title {
        font-size: 1.8rem; } }
@media (min-width: 1000px) {
      .node--type-team-page .bio__title {
        font-size: 2rem; } }
@media (min-width: 1200px) {
      .node--type-team-page .bio__title {
        font-size: 2.6rem; } }
.node--type-team-page .bio__description {
    font-size: 1.3rem; }
@media (min-width: 800px) {
      .node--type-team-page .bio__description {
        font-size: 1.4rem; } }
@media (min-width: 1000px) {
      .node--type-team-page .bio__description {
        font-size: 1.7rem; } }
@media (max-width: 799.5px) {
  body[class*='technical-value'] .pressure-graphs tbody, body[class*='technical-value'] .pressure-graphs tr, body[class*='technical-value'] .pressure-graphs td {
    display: block;
    text-align: center; } }
body[class*='technical-value'] .pressure-graphs td {
  margin-bottom: 2rem; }
.node--type-timeline-page .layout-content:last-child {
  max-width: none; }
.node--type-timeline-page .paragraph--type--header-image .header-image {
  background-position: center bottom; }
@media (min-width: 1200px) {
    .node--type-timeline-page .paragraph--type--header-image .header-image {
      background-attachment: scroll;
      min-height: 65rem; } }
.node--type-timeline-page .paragraph--type--header-image .header-image:before, .node--type-timeline-page .paragraph--type--header-image .header-image:after {
    display: none; }
.node--type-timeline-page .paragraph--type--header-image .page-title {
  top: 25%;
  left: 50%;
  padding: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 15vw;
  text-align: center;
  font-size: 4vw; }
@media (min-width: 1600px) {
    .node--type-timeline-page .paragraph--type--header-image .page-title {
      font-size: 5.6rem; } }
.node--type-timeline-page #block-upwing-page-title {
  display: none; }


/*# sourceMappingURL=index.5bfec2e0.css.map */
