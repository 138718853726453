body.front {
	header {
		position: relative;
		z-index: 1;
		box-shadow: none;
	}

	#block-mainnavigation {
		h2,
		& > ul a {
			color: $white;
		}
	}

	.no-page-header .layout-content {
		padding-top: 0;
	}

	main {
		background: transparent;
		margin-top: -10.8rem;
		padding-left: 0 !important;
		padding-right: 0 !important;

		&:before {
			display: none;
		}
	}

	.hero {
		@include full-width;
		padding: 0;
		position: relative;
		background: $white url('#{$path-img}/home/mountains.jpg') center center no-repeat;
		background-size: cover;
		min-height: 68vw;
		padding-top: 10rem;
		text-align: center;

		@include bp(m) {
			text-align: left;
		}

		&:before {
			@include bp(m) {
				content:"";
				display: block;
				position: absolute;
				bottom: -0.2rem;
				left: 0;
				width: 100%;
				padding-bottom: 47.466%;
				background: transparent url('#{$path-img}/home/hero-vectors.svg') no-repeat;
				background-size: 100% 100%;
			}
		}

		h1 {
			@include aa;
			color: $white;
			font-weight: 100;
			text-align: right;
			font-size: 2.8rem;
			line-height: 1.4;
			margin: 0 3rem;
			max-width: 192rem;

			em {
				font-size: 2.2rem
			}

			@include bp(s) {
				font-size: 4.2rem;
				margin: 0 5rem 0 0;

				em {
					font-size: 3rem
				}
			}

			@include bp(l) {
				font-size: 4.5rem;
				padding-top: 2vw;

				em {
					font-size: 3.5rem
				}
			}

			@include bp(xl) {
				font-size: 5.6rem;
				padding-top: 4vw;
				margin-right: 5vw;

				em {
					font-size: 4.2rem
				}
			}

			@include bp(xxl) {
				padding-top: 5vw;
				margin-right: 10vw;
			}
		}

		img {

			margin: 0 auto 6vw;
			max-width: 90vw;

			@include bp(m) {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				max-width: 80vw;
				padding-bottom: 19vw;
				width: calc(100% + 12rem);
				margin: 0;
			}
		}

		h2 {
			@include aa;
			margin: 0;
			font-weight: 300;
			font-size: 1.6rem;
			text-align: center;
			background: $white;
			padding: 3rem;
			max-width: 65rem;

			@include bp(s) {
				padding: 5rem;
			}

			@include bp(m) {
				background: transparent;
				position: absolute;
				bottom: 0;
				right: 5rem;
				width: 53%;
				padding: 0;
				padding-bottom: 12vw;
				font-size: 1.4rem;
				line-height: 1.4;
				text-align: right;
			}

			@include bp(l) {
				font-size: 1.9rem;
				padding-bottom: 13vw;
			}

			@include bp(xl) {
				font-size: 2.3rem;
			}

			strong {
				font-weight: 400;

				span {
					color: $dark-blue;
				}
			}
		}

		ul {
			@include reset-list(flex);
			flex-flow: row nowrap;
			justify-content: space-around;
			align-content: flex-start;
			align-items: flex-end;
			background: $white;
			padding: 0 3rem 3rem;

			@include bp(m) {
				padding: 0;
				background: transparent;
				position: absolute;
				bottom: 1vw;
				right: 5rem;
				width: 60%;
			}

			li {
				text-align: center;

				&:nth-child(2) {
					margin-left: 1.5rem;
					margin-right: 1.5rem;
				}
			}

			h3 {
				@include aa;
				margin: 0;
				font-weight: 300;
				font-size: 1.8rem;

				@include bp(m) {
					font-size: 2.3rem;
				}

				@include bp(l) {
					font-size: 2.8rem;
				}
			}

			a {
				display: block;
				color: $dark-blue;
				transition: color .25s ease;

				&:hover {
					color: $blue;
				}

				span {
					display: block;
					color: $gray;
					font-size: 1.2rem;
					font-weight: 400;

					&:after {
						content:"";
						background: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17px' height='12px' viewBox='0 0 17 12' enable-background='new 0 0 17 12' xml:space='preserve' fill='%2300aeef'%3E%3Cpath id='right-arrow' d='M16.119,5.209l-4.662-4.885c-0.284-0.298-0.746-0.298-1.03,0c-0.284,0.298-0.284,0.782,0,1.08l3.419,3.582 H1.394c-0.402,0-0.729,0.342-0.729,0.763s0.326,0.763,0.729,0.763h12.452l-3.419,3.582c-0.284,0.298-0.284,0.781,0,1.079 c0.143,0.149,0.329,0.224,0.515,0.224c0.188,0,0.374-0.074,0.516-0.224l4.662-4.884C16.404,5.99,16.404,5.507,16.119,5.209z'/%3E%3C/svg%3E");
						display: block;
						margin: auto;
						width: 1.7rem;
						height: 1.2rem;
						margin-top: .8rem;
					}

					@include bp(m) {
						font-size: 1.4rem;
					}

					@include bp(l) {
						font-size: 1.6rem;
					}
				}
			}
		}
	}

	.center-text {
		padding: 3rem;
		text-align: center;

		@include bp(m) {
			padding: 5rem;
		}

		@include bp(l) {
			@include full-width;
			&.natural-gas {
				background: $white url('#{$path-img}/home/blue-shape.svg') right top no-repeat;
				@include bp(m) {
					margin-top: 2rem;
				}
			}
		}

		h2 {
			font-weight: 100;
			font-size: 3.8rem;
			line-height: 1.1;
			color: $blue;
			margin: 0;

			@include bp(m) {
				font-size: 4.2rem;
				max-width: 78rem;
				margin-left: auto;
				margin-right: auto;
			}
		}

		h3 {
			font-size: 2rem;
			font-weight: 400;
			line-height: 1.2;

			@include bp(m) {
				font-size: 2.2rem;
				margin-top: 1rem;
				max-width: 78rem;
				margin-left: auto;
				margin-right: auto;
			}
		}

		p {
			font-size: 1.6rem;
			margin-top: 2rem;

			@include bp(m) {
				max-width: 78rem;
				margin-left: auto;
				margin-right: auto;
			}
		}

		a {
			font-size: 1.5rem;
			text-transform: uppercase;
			color: $blue;

			&.button:hover {
				color: $white;
			}
		}
	}

	.center-text.natural-gas {
		background-color: transparent;
		position: relative;
		z-index: 0;
	}

	.tour {
		@include full-width;
		position: relative;

		&.locked {

		}

		&__images {
			min-height: 50rem;
			position: sticky;
			z-index: 0;
			height: 100vh;
			width: 100%;
			top:0;
		}
		&__image {
			width: 100%;
			max-width: 120rem;
			margin: 0 auto;
			height: auto;
			opacity: 0;
			position: absolute;
			z-index: 0;
			top:50%;
			left:0;
			right:0;
			transform: translateY(-50%);
			transition: opacity .5s ease;

			&--active {
				opacity: 1;
			}
		}

		&__cards {
			position: relative;
			z-index: 1;
		}

		&__card {
			width: 100%;
			height: 100vh;
			display: flex;
			flex-flow: column;
			align-content: center;
			justify-content: center;

			.card__content {
				align-self: center;
				position: relative;
				z-index: 10;
				transition: .5s transform ease;
				transform: scale(.5);
			}

			&--active {
				.card__content {
					transform: none;
				}
			}
		}

		.card__content {
			color: $white;
			text-align: center;
			background: rgba($black, .7);
			border-radius: .5rem;
			padding: 3rem;
			max-width: 80vw;
			margin: 0 auto;
			will-change: transform;

			@include bp(m) {
				padding: 6rem;
				border-radius: 1rem;
				max-width: 64rem;
			}

			@include bp(l) {
				padding: 8rem;
			}

			h4,p {
				margin: 0;
				padding: 0;
			}

			h4 {
				font-size: 2rem;
				margin-bottom: 3rem;
				font-weight: 700;
			}

			 p {
			 	font-size: 1.6rem;
			 	line-height: 1.8;
			 }

		}
	}

	#before-after {
		@include full-width;

		#comparison {
			position: relative;
			max-width: 150rem;
			min-height: 10rem;
			margin-left: auto;
			margin-right: auto;
		}

		.twentytwenty-handle {
			cursor: grab;
			height:7.2rem;
			width:7.2rem;
			border-width: .6rem;
			box-shadow: none;
			margin-top: -4.2rem;
			margin-left: -4.2rem;

			&:before,
			&:after {
				width: .6rem;
				margin-left: -0.3rem;
				box-shadow: none;
			}

			&:before {
				bottom: calc(100% - 2rem);
			}

			&:after {
				top: calc(100% - 2rem);
			}
		}

		.twentytwenty-left-arrow,
		.twentytwenty-right-arrow,
		.twentytwenty-up-arrow,
		.twentytwenty-down-arrow {
			border: 0.8rem solid transparent;
		}

		.twentytwenty-left-arrow,
		.twentytwenty-right-arrow {
			margin-top: -0.8rem;
		}

		.twentytwenty-left-arrow {
			border-right: 1.2rem solid white;
			margin-left: -2.8rem;
		}

		.twentytwenty-right-arrow {
			border-left: 1.2rem solid white;
			margin-right: -2.8rem;
		}

		.twentytwenty-overlay,
		.twentytwenty-overlay:hover {
			background: transparent;
		}

		.twentytwenty-before-label,
		.twentytwenty-after-label {
			opacity: 1;
			border-radius: 0;

			&:before,
			&:after {
				color: $dark-gray;
				background: rgba($white,.7);
				text-transform: uppercase;
				letter-spacing: .1rem;
				border-radius: 0;
				padding: 0 1.5rem;
				font-size: 1rem;

				@include bp(xs) {
					padding: .2rem 2rem;
					font-size: 1.2rem;
				}

				@include bp(m) {
					padding: 1rem 3rem;
					font-size: 1.4rem;
				}
			}
		}

		.twentytwenty-before-label:before {
			left: 0;
		}
		.twentytwenty-after-label:before {
			right: 0;
		}

		.description {
			padding: 3rem 5rem 0;
			max-width: 140rem;
			margin-left: auto;
			margin-right: auto;

			@include bp(l) {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				margin-top: 5rem;

				& > div {
					flex: 0 0 calc(50% - 2.5rem);
					padding-left: 12rem;
					position: relative;
				}

			}

			.before {
				margin-bottom: 6rem;

				@include bp(m) {
					margin-bottom: 0;
				}
			}

			h3 {
				color: $dark-blue;
				font-weight: 400;
				font-size: 2.2rem;
				margin: 0;

				@include bp(l) {
					position: absolute;
					width: 9rem;
					left: 0;
				}
			}

			ul {
				@include reset-list;

				li {
					position: relative;
					font-size: 1.5rem;
					line-height: 1.3;
					margin: 0 0 1rem;
					padding-left: 3rem;

					&:before {
						position: absolute;
						left: 0;
						content:"\2022";
					}
				}
			}

			p {
				font-size: 1.8rem;
				border: 0.1rem solid $dark-blue;
				border-left: 0;
				border-right: 0;
				padding: 2rem;
			}
		}

	}

	.video-wrapper {
		position: relative;
		max-width: 90rem;
		margin: 0 auto;

		&:before {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -0.2rem;
			background: transparent url('#{$path-img}/home/movie-bkgd.svg') center bottom no-repeat;
			background-size: cover;
			width: 100vw;
			height: 100%;
			transform: translateX(-50%);
		}

	}

	.video {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%;
		cursor: pointer;
		margin-top: 8rem;

		&.active {
			&:after {
				display: none;
			}
			iframe {
				opacity: 1;
			}
		}

		&.oil {
			background: $white url('#{$path-img}/home/oil-movie.jpg') center center no-repeat;
			background-size: cover;

			&:after {
				content:"";
				position: absolute;
				background: transparent url('#{$path-img}/home/play-button.svg') center center no-repeat;
				background-size: contain;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				width: 6.3rem;
				height: 8rem;

				@include bp(m) {
					width: 12.5rem;
					height: 16rem;
				}
			}
		}

		iframe {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
		}
	}

	.features {

		text-align: center;
		margin: 3rem 5rem 6rem;

		@include bp(xl) {
			margin-bottom: 8rem;
			margin-left: 0;
			margin-right: 0;
		}

		ul {
			@include reset-list(flex);
			flex-flow: column nowrap;

			@include bp(m) {
				flex-flow: row wrap;
				justify-content: space-around;
			}

			@include bp(xl) {
				margin-bottom: 5rem;
			}

			li {
				@include aa;
				margin-bottom: 3rem;

				@include bp(m) {
					width: 30%;

					&:nth-child(2) {
						margin-left: 5%;
						margin-right: 5%;
					}
				}
			}
		}

		h4 {
			font-size: 2rem;
			font-weight: bold;
			color: $dark-blue;
			line-height: 1.4;
		}

		p {
			font-size: 1.6rem;
		}

		.button {
			display: inline-block;
		}
	}

	.contact {
		@include full-width;
		background: $light-blue url('#{$path-img}/home/cta-triangles.svg') left top no-repeat;
		background-size: 60% auto;
		position: relative;
		text-align: center;
		padding: 10rem 4rem;
		border-bottom: 2.4rem solid #74C0E2;

		@include bp(s) {
			background-size: 45% auto;
		}

		&__inner {
			max-width: 78rem;
			margin-left: auto;
			margin-right: auto;
		}

		h2 {
			@include aa;
			font-size: 4rem;
			font-weight: 100;
			color: $dark-blue;
			margin: 0;
		}

		p {
			font-size: 1.6rem;
			margin: 0 0 4rem;
		}
	}
	footer[role="contentinfo"] {
		margin-top: 0;
	}
}
