.node__content--bottom {
	position: relative;
	padding: 5rem 0;
	margin-bottom: -5rem;

	@include bp(m) {
		margin-bottom: -10rem;
	}

	&:before {
		@include full-width;
		content:"";
		background: $light-blue;
		position: absolute;
		top:0;
		left:0;
		height: 100%;
		z-index: -1;
		pointer-events: none;
	}

	h2 {
		font-weight: 400;
		font-size: 1.6rem;
		color: $dark-gray;

		@include bp(m) {
			font-size: 2rem;
		}

		@include bp(xl) {
			font-size: 2.4rem;
		}
	}

	.bottom-item {
		border-top: .1rem solid #d8d8d8;
		display: flex;
		flex-flow: column nowrap;

		&__title {
			@include aa;
			font-size: 2rem;
			line-height: 2.4rem;
			margin: 0;
			color: $dark-blue;
			font-weight: 400;
		}

		&__text {
			color: $dark-gray;
			font-size: 1.6rem;

			p {
				margin: 0;
				font-size: 1.6rem;
			}

			a {
				margin-top: 1rem;
				display: block;
				text-transform: uppercase;
				font-weight: 300;
				font-size: 1.4rem;
				color: $blue;
				transition: color .25s ease;

				&:hover {
					color: $dark-blue;
				}
			}
		}
	}

	.bottom-items {
		
		@include bp(m) {
			flex-flow: row nowrap;
		}

		.bottom-item {
			padding: 3rem 0;
			display: flex;
			flex-flow: column nowrap;

			@include bp(m) {
				flex-flow: row nowrap;
			}

			&__content {
				@include bp(m) {
					width: 100%;
					display: flex;
					flex-flow: row nowrap;
				}
			}

			&__title {
				@include bp(m) {
					flex: 0 0 30%;
					margin-right: 2rem;
				}
			}

			&__text {
				@include bp(m) {
					flex: 0 1 auto;
				}
			}
		}
		
		&.grid {
			display: flex;
			flex-flow: column nowrap;

			@include bp(m) {
				flex-flow: row wrap;
			}

			.bottom-item {
				padding: 3rem 0;
				border-top: 0;
				
				&:nth-child(-n+3) {
					@include bp(m) {
						border-bottom: .1rem solid rgba($blue,.25);
					}
				}

				@include bp(m) {
					width: calc(100% / 3);
					flex: 0 0 calc(100% / 3);
					flex-flow: column nowrap;
					padding: 3rem 2rem;
					margin-bottom: 2rem;
				}

				&__content {
					@include bp(m) {
						display: block;
					}
				}

				&__title {
					margin-bottom: 1rem;
					flex: 0 1 auto;
				}
				&__text {
					flex: 0 1 auto;
				}
			}
		}
	}
}