.node--type-careers-page {
	.layout-container:before {
		display: none;
	}

	main[role="main"].no-page-header .layout-content {
		padding-top: 0;
		max-width: 120rem;
	}

	#block-upwing-page-title {
		text-align: center;
	}

	#block-upwing-content .node__content {
		overflow: visible;
	}

	.node__body {
		text-align: center;
		max-width: 86rem;
		margin: 0 auto 5rem;

		h3 {
			@include aa;
			letter-spacing: -.025rem;
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 1.4;

			@include bp(m) {
				font-size: 1.9rem;
			}

			@include bp(xl) {
				font-size: 2.4rem;
			}
		}
	}

	#block-upwing-content .careers {
		&__hero {
			@include full-width;
			height: 0;
			background-size: cover;
			background-position: center center;
			padding-bottom: calc(100% / 2.5);
			margin-bottom: 5rem;

			@include bp(m) {
				margin-bottom: 0;
			}

			@include bp(l) {
				background-attachment: fixed;
			}

		}

		&__highlights {
			display: flex;
			flex-flow: column nowrap;
			padding-bottom: 5rem;

			@include bp(m) {
				margin-top: -9.5rem;
				flex-flow: row nowrap;
				justify-content: space-between;
			}
		}

		&-highlights__item {
			text-align: center;

			@include bp(m) {
				width: 32%;
			}
		}

		&-highlights__heading {
			@include aa;
			font-weight: 700;
			color: $dark-blue;
			font-size: 1.7rem;
			letter-spacing: -.025rem;
			margin: 2rem 0 1rem;
			padding: 0 10%;

			@include bp(m) {
				font-size: 2.2rem;
			}

			@include bp(xl) {
				font-size: 2.5rem;
			}
		}

		&__benefits {
			position: relative;
			padding: 2rem 0;

			@include bp(m) {
				padding: 5rem 0;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
			}

			h2 {
				@include aa;
				text-align: center;
				font-weight: 100;
				font-size: 3rem;
				margin: 0 0 2rem;
				padding: 0;
				width: 100%;

				@include bp(m) {
					font-size: 4.5rem;
				}

				@include bp(xl) {
					font-size: 5.6rem;
				}
			}

			h2 + p {
				@include aa;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 1.4;
				letter-spacing: -.025rem;
				padding: 0 10%;
				margin: 0 0 3rem;
				width: 100%;

				@include bp(m) {
					font-size: 1.9rem;
				}

				@include bp(xl) {
					font-size: 2.4rem;
				}
			}

			& > div {
				width: 100%;

				h3 {
					color: $dark-blue;
					font-weight: 700;
					font-size: 1.6rem;

					@include bp(m) {
						font-size: 1.8rem;
					}

					@include bp(xl) {
						font-size: 2.4rem;
					}
				}

				p {
					font-size: 1.5rem;

					@include bp(m) {
						font-size: 1.6rem;
					}

					@include bp(xl) {
						font-size: 1.9rem;
					}
				}
			}

		}

		&__join,
		&__contact {
			text-align: center;
			padding: 5rem 0;

			@include bp(m) {
				padding: 8rem 0;
			}

			h2 {
				font-weight: 100;
				font-size: 3rem;

				@include bp(m) {
					font-size: 4.4rem;
				}
				@include bp(xl) {
					font-size: 5.6rem;
				}
			}

			p {
				font-size: 1.6rem;

				@include bp(m) {
					font-size: 1.9rem;
				}
				@include bp(xl) {
					font-size:2.4rem;
				}
			}
		}

		&__contact {
			position: relative;
      & > * {
        max-width: 86rem;
        margin-left: auto;
        margin-right: auto;
      }

			&:before {
				@include full-width;
				content:"";
				display: block;
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: $navy-blue;
				z-index: -1;
			}

			p {
				color: $white;
			}
		}
    &__join {
      position: relative;
      padding: 2rem 0!important;
      @include bp(m) {
        padding: 2rem 0!important;
      }

      h2,
      h3 {
        max-width: 90rem;
        margin-left: auto;
        margin-right: auto;
      }
      h2 {
        margin-bottom: 10px;
      }
      h3 {
        webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4;
        letter-spacing: -.025rem;
      }
      h4 {
        color: #6f6f6f!important;
        font-size: 18px!important;
        font-weight: 500!important;
      }

      &:before {
        @include full-width;
        content:"";
        display: block;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: $light-gray;
        z-index: -1;
      }
    }
	}
	footer {
		margin-top: 0;
	}

}
