body[class*='path-contact'] {
	#block-upwing-page-title h1 {
		@include aa;
		text-align: center;
		color: $blue;
		font-weight: 100;
		font-size: 3rem;
		@include bp(m) {
			font-size: 5.6rem;
		}
	}

	input.webform-button--submit {
		background-color: $blue;
		color: $white;
		text-transform: none;
		cursor: pointer;
		box-shadow: 0 .25rem 1rem rgba(0,0,0,0);
		transition: all .15s ease;

		&:hover {
			background: $dark-blue;
			box-shadow: 0 .25rem 1rem rgba(0,0,0,.25);
		}
	}

	#block-contactbottom {
		display: block;
		background: $navy-blue;
		margin-top: 5rem;

		& > div {
			padding: 5rem 3rem;

			@include bp(m) {
				padding: 5rem;
			}
		}

		h3 {
			color: $dark-blue;
			font-size: 1.8rem;
			margin: 0 0 2rem;

			@include bp(m) {
				font-size: 2rem;
			}

			@include bp(xl) {
				font-size: 2.4rem;
			}
		}
		p {
			color: $white;
			font-size: 1.3rem;

			@include bp(m) {
				font-size: 1.4rem;
			}

			@include bp(xl) {
				font-size: 1.6rem;
			}

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
		a {
			color: $dark-blue;
		}
		.wrap {
			max-width: 92rem;
			margin: 0 auto;

			@include bp(m) {
				display: flex;
				flex-flow: row nowrap;
			}
		}
		#footer-contact {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 4rem;

			& > div {
				width: 50%;
			}

			@include bp(m) {
				width: calc(100% - 32rem);
				margin-bottom: 0;
			}
		}
		#footer-map {
			position: relative;
			height: 0;
			overflow: hidden;
			padding-bottom: 50%;

			@include bp(m) {
				width: 32rem;
				padding-bottom: 30%;
			}

			@include bp(xl) {
				padding-bottom: 25%;
			}

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	main[role="main"]:before {
		display: none;
	}

	#block-upwing-page-title {
		text-align: center;
	}

	.webform-confirmation__message {
		text-align: center;
		font-size: 1.4rem;
		max-width: 70rem;
		margin: 0 auto;
	}

	.webform-confirmation__back {
		display: none;
	}

	footer[role="contentinfo"] {
		margin-top: 0;
	}
}

#webform-submission-contact-add-form {
  margin: 0 auto;
  width: 100%;
  max-width: 450px;

	& .form-item {
		width: 100%;
    max-width: 450px;
    margin-bottom: 20px;

		input, select, textarea {
			width: 100%;
		}

		input[type="checkbox"],
		input[type="radio"] {
			width: auto;
		}

	}

	& > .form-item-website,
	& > .form-item-description {
		width: 100%;
	}
  
}
