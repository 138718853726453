.layout-container {
	display: flex;
	flex-flow: column nowrap;
	align-content: flex-start;
	justify-content: flex-start;

	&::before {
		content:"";
		background: $white url('#{$path-img}/blue-triangle.svg') top left no-repeat;
		background-size: 50% auto;
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
		z-index: -1;
		top: 10.8rem;
		left: 0;
	}

	header {
		flex: 0 0 10.8rem;
	}

	main {
		flex: 1 1 auto;
	}

	footer {
		flex: 0 1 auto;
	}

}

// Adjust top-left graphic based on admin toolbar(s) active
.logged-in {
	.layout-container::before {
		top: 14.8rem;
	}
}

.logged-in.toolbar-fixed {
	.layout-container::before {
		top: 14.8rem;

		@include bp(l) {
			top: 14.8rem;
		}		
	}
}

.logged-in.toolbar-fixed.toolbar-horizontal.toolbar-tray-open {
	.layout-container::before {
		top: 14.8rem;

		@include bp(l) {
			top: 18.8rem;
		}
	}
}