header[role="banner"] {
	height: 10.8rem;
	position: relative;
	box-shadow: 0 .5rem 2rem 0 rgba(0,0,0,.1);

	& > div {
		@include global-padding;
		height: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		max-width: 140rem;

		@include bp(m) {
			padding-top: 1rem;
		}
	}
}

#block-upwing-branding,
#block-logowhite {
	img {
		width: 17rem;
		height: auto;
		@include bp(s) {
			width: 20rem;
			height: 4.2rem;
		}
	}
}