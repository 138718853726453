@mixin reset-list($display:block,$li-display:block) {
	margin: 0;
	padding: 0;
	list-style: none;
	display: $display;

	li {
		margin: 0;
		padding: 0;
		list-style: none;
		display: $li-display;
	}
}

@mixin global-padding {
	padding-left: 2rem;
	padding-right: 2rem;
	transition: padding .25s ease;

	@include bp(m) {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	@include bp(l) {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	@include bp(xl) {
		padding-left: 5rem;
		padding-right: 5rem;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		max-width: 160rem;
	}
}

@mixin full-width {
	width: 100vw;
	margin-left: unquote('calc(50% - 50vw)');
	margin-right: unquote('calc(50% - 50vw)');
}

@mixin aa {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin arrow-right {
	position: relative;

	&:after {
		content:"";
		display: inline-block;
		height: 0;
		width: 0;
		border: .5rem solid transparent;
		border-left: 1rem solid $blue;
		margin-left: 1rem;
	}
}