footer[role="contentinfo"] {
	background: $dark-blue;
	color: $white;
	padding: 5.5rem 0;
	margin-top: 5rem;

	@include bp(m) {
		margin-top: 8rem;
	}

	& > div {
		@include global-padding;
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: flex-start;

		@include bp(m) {
			flex-flow: row nowrap;
		}
	}

	a {
		color: $white;
	}

	p {
		margin: 0;
	}
}