.node--type-article {
	#block-upwing-page-title {
		h1 {
			font-size: 3.3rem;
		}
	}
	.layout-content {
		@include bp(m) {
			margin-right: 25%;
		}
	}
}