$bp_xs: 360px;
$bp_s: 600px;
$bp_m: 800px;
$bp_l: 1000px;
$bp_xl: 1200px;
$bp_xxl: 1600px;

@mixin bp($point) {
  @if $point == xs {
    @media (min-width: $bp_xs) {
      @content;
    }
  }
  @else if $point == xs_max {
    @media (max-width: $bp_xs - 0.5) {
      @content;
    }
  }
  @if $point == s {
    @media (min-width: $bp_s) {
      @content;
    }
  }
  @else if $point == s_max {
    @media (max-width: $bp_s - 0.5) {
      @content;
    }
  }
  @else if $point == m {
    @media (min-width: $bp_m) {
      @content;
    }
  }
  @else if $point == m_max {
    @media (max-width: $bp_m - 0.5) {
      @content;
    }
  }
  @else if $point == l {
    @media (min-width: $bp_l) {
      @content;
    }
  }
  @else if $point == l_max {
    @media (max-width: $bp_l - 0.5) {
      @content;
    }
  }
  @else if $point == xl {
    @media (min-width: $bp_xl) {
      @content;
    }
  }
  @else if $point == xl_max {
    @media (max-width: $bp_xl - 0.5) {
      @content;
    }
  }
  @else if $point == xxl {
    @media (min-width: $bp_xxl) {
      @content;
    }
  }
  @else if $point == xxl_max {
    @media (max-width: $bp_xxl - 0.5) {
      @content;
    }
  }
}