.paragraph--type--header-image + .layout-content {
	#block-upwing-page-title {
		display: none;
	}
}

#block-upwing-page-title h1 {
	@include aa;
	font-weight: 100;
	color: $dark-blue;
	font-size: 4rem;
	line-height: 1.2;

	@include bp(m) {
		font-size: 5.6rem;
	}
}

#block-upwing-content {
	.node__content {
		overflow: hidden;

		img {
			@include bp(m_max) {
				width: 100%;
			}
		}

		a {
			color: $blue;
		}

		h1 {
			@include aa;
			font-weight: 100;
			font-size: 4rem;
			color: $blue;
			margin: 3rem 0;

			@include bp(m) {
				font-size: 5.6rem;
				margin: 6rem 0 3rem;
			}
		}

		h1 + p.intro {
			margin-top: 0;
		}

		p.intro {
			@include aa;
			position: relative;
			font-size: 2.4rem;
			line-height: 3.6rem;
			margin: 0;
			padding: 0;

			&:after {
				content:"";
				width: 15rem;
				height: 0.1rem;
				margin: 4rem 0;
				background: $blue;
				display: block;
			}
		}

		h2:first-child,
		p.intro + h2 {
			margin-top: 0;
		}

		h2 {
			@include aa;
			color: $dark-blue;
			font-weight: 400;
			font-size: 2.8rem;
			margin-top: 6rem;
		}

		h3 {
			color: #6f6f6f;
			font-size: 2rem;
			font-weight: 500;
		}

		p {
			font-size: 1.6rem;
		}

		ul {
			li {
				margin-bottom: 2rem;
				font-size: 1.6rem;

				ul {
					margin-top: 2rem;

					li {
						list-style: none;
						position: relative;

						&:before {
							content: "-";
							position: absolute;
							left: -2rem;
							font-weight: 800;
						}
					}
				}
			}
		}

		ol {
			@include reset-list;
			counter-reset: ol;
			li {
				position: relative;
				padding-left: 5rem;

				&:before {
					counter-increment: ol;
					content: counter(ol);
					position: absolute;
					color: $white;
					background: $dark-blue;
					border-radius: 50%;
					height: 2.8rem;
					width: 2.8rem;
					vertical-align: middle;
					line-height: 2.8rem;
					text-align: center;
					font-size: 1.8rem;
					left: 0;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						line-height: 1.5;
					}

				}

				strong {
					color: $dark-blue;
					font-weight: 500;
					display: block;
					margin-bottom: -2rem;
				}

				a {
					margin-top: 1rem;
					display: block;
					text-transform: uppercase;
					font-weight: 300;
					font-size: 1.4rem;
					color: $blue;
					transition: color .25s ease;

					&:hover {
						color: $dark-blue;
					}
				}

			}
		}

		table {
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				table-layout: fixed;
			}
		}

		table caption {
			margin-top: 2rem;
		}

		table,table caption,th,tbody,tr,td {
			@include bp(s_max) {
				display: block;
				width: 100% !important;
			}
			img {
				@include bp(s_max) {
					max-width: 80%;
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}