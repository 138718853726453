
#block-upwing-content .node__content {

  .case-study-triptych {
    border: .1rem solid $light-gray;
    position: relative;
    width: 100%;
    margin-bottom: 5rem;

    @include bp(m) {
      padding-right: 50%;
    }

    h3 {
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: 500;
      font-size: 2rem;
      margin: 0 0 1rem;
    }

    p {
      font-size: 1.6rem;
      margin: 0 0 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & > div {
      @include bp(m) {
        border-right: .1rem solid $light-gray;
      }

      & > div {
        border: 1.5rem solid $white;
        background: lighten($light-blue,4%);
        padding: 5rem;
      }
    }

    &__challenge {
      border-bottom: .1rem solid $light-gray;

    }
    &__solution {
      @include bp(m_max) {
        border-bottom: .1rem solid $light-gray;
      }

    }
    &__results {
      @include bp(m) {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100% !important;
        border: none !important;
      }
      & > div {
        height: 100%;
        background: $light-blue !important;
      }
    }
  }

  table {
    td {
      img {
        width: 80%;
      }
    }
  }
  ol {
    list-style: decimal;
    padding: 5rem 7rem;
    background: $light-blue;
    li {
      display: list-item;
      list-style: decimal;
      padding-left: 0;
      margin-bottom: 2rem;
      font-size: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        display: none;
        list-style: list-item;
      }
      strong {
        font-weight: 700;
        color: $gray;
        display: inline;
        margin-bottom: 0;
      }
    }
  }
}
