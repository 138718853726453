/* chronos grid styling */
.chronos-grid{ display: flex; width: 100%; box-sizing: border-box; }
.chronos-grid__child{ display: block; box-sizing: border-box; min-height:40px; padding: 0 20px; }
.chronos-grid__child:first-child{ padding-right:20px; }
.chronos-grid__child:last-child{ padding-left:20px; }
.chronos-grid__child img{ max-width:100%; height:auto; }
.chronos-grid__child > *:first-child{ margin-top:0; }
.chronos-grid--6-6 .chronos-grid__child:first-child{flex-basis:50%}
.chronos-grid--6-6 .chronos-grid__child:last-child{flex-basis:50%}
.chronos-grid--4-8 .chronos-grid__child:first-child{flex-basis:33.333333%}
.chronos-grid--4-8 .chronos-grid__child:last-child{flex-basis:66.666666%}
.chronos-grid--8-4 .chronos-grid__child:first-child{flex-basis:66.666666%}
.chronos-grid--8-4 .chronos-grid__child:last-child{flex-basis:33.333333%}
.chronos-grid--3-9 .chronos-grid__child:first-child{flex-basis:25%}
.chronos-grid--3-9 .chronos-grid__child:last-child{flex-basis:75%}
.chronos-grid--9-3 .chronos-grid__child:first-child{flex-basis:75%}
.chronos-grid--9-3 .chronos-grid__child:last-child{flex-basis:25%}
@include bp(s_max){
  .chronos-grid{ display: block; }
  .chronos-grid .chronos-grid__child{ width:100%; padding-left:0; padding-right:0; margin-bottom: 20px;  }
  .chronos-grid__child.is-empty{display: none;}
}

.chronos-grid {

  text-align: left;

  p {
    font-size: 16px!important;
    text-align: left;
  }

  ul,
  ol {
    text-align: left;
    margin: 10px 0!important;
    padding: 0 0 0 30px!important;
    background-color: transparent!important;

    li {
      margin-bottom: 10px!important;
    }
  }
}


// Ckeditor alignment classes
.text-align-center {
  text-align: center!important;
}
.text-align-left {
  text-align: left!important;
}
.text-align-right {
  text-align: right!important;
}
.text-align-justify {
  text-align: justify!important;
}

h3.blue-heading {
  color: #0194d3!important;
  font-weight: 700!important;
  font-size: 18px!important;
}
