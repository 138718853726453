$white: #fff;
$black: #000;

$gray: #6f6f6f;
$darker-gray: #3c3c3c;
$dark-gray: #65666a;
$light-gray: #d8e3e9;
$lighter-gray: #f4f6f8;

$blue: #00aeef;
$dark-blue: #0194d3;
$light-blue: #e7eef2;
$navy-blue: #002b54;

$data-red: #D0021B;
$data-orange: #ff9600;
$data-green: #7ed321;
$data-blue: #0980b2;

$mobile-nav-bg: $navy-blue;
$mobile-nav-sub: #006497;
$mobile-nav-border: #777;

$error: $data-red;

