#block-upwing-footer {
	margin-bottom: 2rem;

	@include bp(m) {
		margin-bottom: 0;
	}
	
	& > ul {
		@include reset-list(block, inline-block);
		li {
			text-transform: uppercase;
			margin-right: 2rem;
		}
	}
}