#block-upwing-content .node__content {
	.timeline {
		position: relative;
		margin-top: -0.2rem;
		transform: translateX(0.05rem);
		overflow: hidden;
		margin-bottom: 5rem;

		&:before {
			content:"";
			display: block;
			position: absolute;
			top:0;
			left: calc(50% - .1rem);
			height: 100%;
			width: .1rem;
			background: $dark-blue;

			@include bp(xl) {
				width: .2rem;
			}
		}

		&__item {
			width: 50%;
			margin-bottom: 5rem;

			@include bp(m) {
				margin-bottom: 10rem;
			}

			&:nth-child(2) {
				margin-top: 8vw;
			}

			&--active {
				.timeline__item-content {
					opacity: 1;
					transform: none;
				}
			}
		}

		&__item-content {
			opacity: 0;
			transform: translateY(5rem);
			transition: all .5s ease;
		}

		&__title {
			color: $dark-blue;
			font-weight: 700;
			font-size: 1.8rem;
			margin: 0;
			border-bottom: .1rem solid $dark-blue;
			padding: 1.5rem 5rem .5rem 2rem;

			@include bp(m) {
				font-size: 2.4rem;
			}

			@include bp(l) {
				font-size: 3rem;
				padding: 1.5rem 12rem .5rem 10rem;
			}

			@include bp(xl) {
				border-width: .2rem;
			}
		}

		&__text {
			padding: 1.5rem 5rem 1.5rem 2rem;
			margin: 0;
			font-size: 1.7rem;

			@include bp(l) {
				padding: 1.5rem 12rem 1.5rem 10rem;
			}
		}
	}
}