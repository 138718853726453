.carousel {

	display: flex;
	flex-flow: column nowrap;

	@include bp(m) {
		flex-flow: row nowrap;
	}

	&__items {
		width: 100%;

		@include bp(m) {
			margin-right: 5rem;
			width: 25rem;
		}
		@include bp(l) {
			width: 30rem;
		}
		@include bp(xl) {
			width: 40rem;
		}
	}

	&__item {
		@include bp(m) {
			width: 25rem;
		}
		@include bp(l) {
			width: 30rem;
		}
		@include bp(xl) {
			width: 40rem;
		}
	}

	&__image {
		width: 100%;
		text-align: center;

		img {
			margin: auto;
		}
	}

	&__caption {
		text-align: center;
		width: 100%;
		min-height: 5rem;

		p {
			@include aa;
			font-weight: 500;
			font-size: 1.6rem;

			small {
				display: block;
				font-style: italic;
				font-size: 1.3rem;
			}
		}
	}

	&__pager {
		@include reset-list(block,inline-block);
		width: 100%;
		text-align: center;
		margin-top: 2rem;

		&-item {
			height: 1.5rem;
			width: 1.5rem;
			margin: 0 .5rem !important;

			a {
				display: block;
				border: .1rem solid $black;
				background: $white;
				height: 1.5rem;
				width: 1.5rem;
				border-radius: 50%;
				text-indent: -999rem;
				overflow: hidden;
			}

			&--active {
				a {
					background: $dark-blue;
				}
			}
		}
	}

	&__text {
		@include bp(m) {
			align-self: center;

			p {
				max-width: 48rem;
			}
		}
	}
}