#block-mainnavigation {
	&.menu-active {
		h2 {
			color: transparent;

			&:after {
				background-image: url('#{$path-img}/icon-close.svg');
				background-size: contain;
			}
		}
	}

	h2 {
		@include aa;
		cursor: pointer;
		margin: 0;
		padding: 0 1rem;
		text-transform: uppercase;
		font-weight: 100;
		font-size: 2.1rem;
		height: 3rem;
		line-height: 3rem;
		text-align: right;
		position: relative;
		z-index: 999;

		&:after {
			content:"";
			display: inline-block;
			margin-left: 1.5rem;
			height: 3rem;
			width: 3rem;
			vertical-align: bottom;
			background: transparent url('#{$path-img}/icon-menu.svg') center center no-repeat;
		}

		@include bp(xs_max) {
			padding: 0;
			overflow: hidden;
			text-indent: -999rem;
			width: 3rem;
		}

		@include bp(s) {
			font-size: 2.4rem;
		}

		@include bp(m) {
			display: none;
		}
	}

	& > ul.menu-active {
		opacity: 1;
		pointer-events: auto;
		transform: none;
		z-index: 998;
	}

	& > ul {
		@include reset-list(block);
		flex-flow: column nowrap;
		padding-top: 10rem;
		position: fixed;
		pointer-events: none;
		z-index: -1;
		opacity: 0;
		top: 0;
		right: 0;
		left:0;
		width:100%;
		height:100%;
		background: $mobile-nav-bg;
		transition: transform .5s ease;
		transform: translateX(100%);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		@include bp(m) {
			pointer-events: auto;
			opacity: 1;
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
			position: static;
			padding: 0;
			width: auto;
			height: auto;
			background: transparent;
			transform: none;
			overflow: visible;
		}

		a {
			font-size: 2.1rem;
			color: $white;
			display: block;
			padding: 1rem;
			text-transform: uppercase;

			&.nolink {
				cursor: default;
			}
			
			@include bp(m) {
				color: $gray;
				font-size: 1.5rem;
				font-weight: 300;
				padding: .5rem 1rem;
				transition: all .25s ease;

				&:hover {
					background: $blue;
					color: $white;
				}
			}
			@include bp(l) {
				font-size: 1.65rem;
				padding: .5rem 1.5rem;
			}
		}

		& > li:first-child {
			@include bp(m) {
				display: none;
			}
		}

		& > li:hover,
		& > li:active,
		& > li:focus {
			& > a {
				background: $blue;
				color: $white;
			}
			.menu {
				@include bp(m) {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}

		li {
			width: 100%;
			display: block;
			border-bottom: .1rem solid $mobile-nav-border;
			position: relative;

			@include bp(m) {
				border: none;
				width: auto;
			}
		}

		& > .menu-item--active-trail {
			position: relative;

			@include bp(m) {
				&:before {
					content:"";
					display: block;
					width: 100%;
					height: .2rem;
					position: absolute;
					bottom:0;
					background: $blue;
					pointer-events: none;
				}
			}
		}

		li.menu-item--expanded {
			@include bp(m) {
				& > a:after {
					content: "";
					display: inline-block;
					height: 0;
					width: 0;
					border: .6rem solid transparent;
					border-top-color: $blue;
					margin-left: 1rem;
					transform:translateY(.3rem);
					transition: border-color .25s ease;
				}

				&:hover > a:after,
				&:active > a:after,
				&:focus > a:after {
					border-top-color: $white;
				}
			}
		}

		ul {
			@include reset-list;
			background: $mobile-nav-sub;

			@include bp(m) {
				opacity: 0;
				pointer-events: none;
				will-change: opacity;
				transition: all .25s ease;
				position: absolute;
				z-index: 99;
				top: 100%;
				right: 0;
			}

			li {
				border: none;
				
				a {
					color: $white;
					font-size: 1.8rem;
					padding: 1rem 2.5rem;

					@include bp(m) {
						white-space: nowrap;
						font-size: 1.5rem;
						padding: .75rem 1.5rem;
						text-align: right;
					}

					&:hover,
					&:active {
						background: $blue;
					}
				}
			}
		}
	}

	// hide contextual links on mobile
	.contextual {
		@include bp(m_max) {
			display: none !important;
		}
	}
}

// Prevent page scrolling while mobile menu is active
body.menu-active {
	overflow: hidden;
	position: fixed;
	height: 100vh;
	width: 100vw;
}

// Adjust mobile menu for admin toolbar
.logged-in {
	#block-mainnavigation {
		& > ul {
			top: 39px;
			height: calc(100% - 39px);
		}
	}
}