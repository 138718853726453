.node--type-news-events-landing, .path-news-and-events {
	.layout-container:before {
		display: none;
	}

	main[role="main"].no-page-header [class^="layout-"] {
		padding-top: 0;
	}

	.layout-content:last-child {
		max-width: none;
	}

	#block-upwing-page-title {
		text-align: center;

		@include bp(m) {
			margin-top: 5rem;
		}

		h1 {
			&:after {
				content:"";
				display: block;
				width: 15rem;
				height: .1rem;
				background: $dark-blue;
				margin: 1.5rem auto;

				@include bp(m) {
					margin: 3rem auto;
				}
			}
		}
	}
	.node__content, .view-id-news {
		display: flex;
		flex-flow: column nowrap;

		@include bp(m) {
			flex-flow: row nowrap;
			align-items: flex-start;
			justify-content: space-between;
		}

		a {
			color: $dark-blue;
		}

		.node__news {
			flex: 0 1 auto;

			@include bp(m) {
				flex: 0 0 calc(65% - 8rem);
			}

		}
		.node__events {
			padding: 5rem;
			background: $light-gray;

			@include bp(m) {
				flex: 0 0 33%;
			}
		}
	}
	#block-upwing-content .node__content, #block-upwing-content .view-id-news {
		.views-row:not(:first-child) {
			margin-top: 5rem;
		}
		.news-event {

			& + .news-event {
				margin-top: 5rem;
			}

			&__date {
				color: $dark-blue;
				text-transform: uppercase;
				font-size: 1.2rem;
				font-weight: 300;
				margin: 0 0 1rem;

				@include bp(m) {
					font-size: 1.5rem;
				}

				@include bp(xl) {
					font-size: 2.1rem;
				}

			}

			&__title {
				@include aa;
				font-weight: 700;
				color:$dark-blue;
				font-size: 1.8rem;
				line-height: 1.1;
				margin: 0;

				@include bp(m) {
					font-size: 2.4rem;
				}

				@include bp(xl) {
					font-size: 3rem;
				}
			}

			&__text {
				font-size: 1.2rem;

				@include bp(m) {
					font-size: 1.3rem;
				}

				@include bp(xl) {
					font-size: 1.6rem;
				}
			}

			&__link {
				a {
					font-weight: 400;
					color: $dark-blue;
					font-size: 1.5rem;

					@include bp(m) {
						font-size: 1.6rem;
					}

					@include bp(xl) {
						font-size: 2rem;
					}
				}
			}
		}

		.node__events {

			margin-top: 5rem;

			@include bp(m) {
				margin-top: 0;
			}

			.views-row + .views-row {
				margin-top: 3rem;
			}

			h3 {
				color: $dark-blue;
				border-bottom: .1rem solid $dark-blue;
		    padding-bottom: 2rem;
		    margin-bottom: 3rem;
		    margin-top: 0;
		    font-weight: 400;
		    font-size: 1.6rem;

		    @include bp(m) {
					font-size: 1.9rem;
				}

				@include bp(xl) {
					font-size: 2.4rem;
				}
			}

			.news-event {
				&__date {
					margin-bottom: 0;
				}

				&__title {
					font-size: 1.5rem;
					margin-bottom: 1.5rem;

					@include bp(m) {
						font-size: 2rem;
					}

					@include bp(xl) {
						font-size: 2.5rem;
					}
				}
				&__text {
					margin-bottom: 1rem;
					p {
						margin: 0;
					}
				}
				&__link {
					a {
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}