.node--type-timeline-page {
	.layout-content:last-child {
		max-width: none;
	}
	.paragraph--type--header-image {
		.header-image {
			background-position: center bottom;

			@include bp(xl) {
				background-attachment: scroll;
				min-height: 65rem;
			}

			&:before,
			&:after {
				display: none;
			}
		}
		.page-title {
			top: 25%;
			left: 50%;
			padding: 0;
			transform: translateX(-50%);
			width: 15vw;
			text-align: center;
			font-size: 4vw;

			@include bp(xxl) {
				font-size: 5.6rem;
			}
		}
	}
	#block-upwing-page-title {
		display: none;
	}
}