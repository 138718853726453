form {
	label {
		@include aa;
		display: block;
		margin-bottom: .3rem;
		font-size: 1.8rem;
		font-weight: 500;

		@include bp(m) {
			margin-bottom: .5rem;
		}

		&.form-required {
			&:after {
				content:"\00a0*";
				color: $blue;
			}
		}
	}
	input,select,textarea {
		font-family: $roboto;
		font-size: 1.4rem;
		color: $gray;
		appearance: none;
		outline: none;
		padding: 1rem 1.25rem;
		border: .1rem solid $light-gray;
		border-radius: .5rem;
		transition: all .25s ease;

		&:focus {
			border-color: $gray;
		}
	}

	.js-form-type-checkbox,
	.js-form-type-radio {
		label {
			vertical-align: top;
			display: inline-block;
			margin-bottom: 0;
		}
		input {
			box-sizing: content-box;
			height: 2rem !important;
			width: 2rem !important;
			padding: 0;
			background: $white url('#{$path-img}/check.svg') center center no-repeat;
			background-size: 14px auto;
			background-image: none;

			&:checked {
				background-image: url('#{$path-img}/check.svg');
			}
		}
	}
	
}