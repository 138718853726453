body[class*='technical-value'] {
	.pressure-graphs {
		tbody,tr,td {
			@include bp(m_max) {
				display: block;
				text-align: center;
			}
		}
		td {
			margin-bottom: 2rem;
		}
	}
}