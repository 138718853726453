.paragraph--type--header-image {
	position: relative;
	flex: 1 1 auto;
	width: 100%;

	.header-image {
		@include full-width;
		position: relative;
		overflow: hidden;
		background-size: cover;
		background-position: center top;
		height: 30vw;
		min-height: 12rem;
		transition: min-height .25s ease;

		@include bp(s) {
			min-height: 15rem;
		}
		@include bp(m) {
			min-height: 20rem;
		}
		@include bp(l) {
			min-height: 30rem;
		}
		@include bp(xl) {
			min-height: 40rem;
			background-attachment: fixed;
		}
		@include bp(xxl) {
			min-height: 50rem;
			max-height: 70rem;
		}

		&:before,
		&:after {
			content:"";
			display: block;
			opacity: 0.5;
			position: absolute;
			top:0;
		}

		&:before {
			left:0;
			width: 90%;
			padding-bottom: calc(90% / 3.32);
			background: transparent url('#{$path-img}/light-blue.png') 0 0 no-repeat;
			background-size: contain;
			transform: rotate(180deg) scale3d(-1, 1, 1);
		}

		&:after {
			right:0;
			width: 52%;
			padding-bottom: calc(52% / 3.58);
			background: transparent url('#{$path-img}/dark-blue.png') 0 0 no-repeat;
			background-size: contain;
			transform: rotate(180deg) scale3d(-1, 1, 1);
		}

	}

	.header-image + .page-title {
		@include aa;
		margin: 0;
		position: absolute;
		top: 30%;
		color: $white;
		font-weight: 300;
		font-size: 2.6rem;
		line-height: 1.2;
		text-shadow: .3rem .3rem 1rem rgba($black,.1);

		@include bp(m) {
			font-size: 3.6rem;
		}
		@include bp(l) {
			font-size: 5.6rem;
		}
		@include bp(xl) {
			padding-left: 5%;
		}
	}
}