.path-simulation {
	#block-upwing-content .node {
		&__content {

			h4 {
				@include aa;
				font-size: 2rem;
				font-weight: 500;
			}

			.input-data {

				& > h4 {
					margin-bottom: 1rem;
				}

				&__example {
					margin: 0 0 4rem;
					p {
						margin: 0;
						text-transform: lowercase;
						font-size: 1.4rem;
					}
				}

				&__legend {
					@include reset-list(block,inline-block);

					border: .1rem solid $light-gray;
					border-left: none;
					border-right: none;
					padding: 1rem 0;
					margin: 0 0 4rem;

					li {
						margin-right: 5rem;
						line-height: 2.8rem;
						font-size: 1.4rem;
						width: 100%;
						margin-bottom: 2rem;

						&:last-child {
							margin-bottom: 0;
						}

						@include bp(m) {
							width: auto;
							margin-bottom: 0;
						}

						&:before {
							content:"";
							display: inline-block;
							width: 2.8rem;
							height: 2.8rem;
							margin-right: 2rem;
							vertical-align: bottom;
						}

						&.a-req:before {
							background: $data-red;
						}
						&.req:before {
							background: $data-orange;
						}
						&.opt:before {
							background: $data-green;
						}
						&.upwing:before {
							background: $data-blue;
						}
					}
				}

				&__table {
					@include reset-list(flex);
					flex-flow: row wrap;

					& > li {
						width: 100%;
						margin: 0 0 5rem;

						@include bp(s) {
							width: 50%;
							padding-right: 3rem;
						}
						@include bp(m) {
							width: 33.33%;
						}
						@include bp(xl) {
							width: 16.66%;
							margin-bottom: 0;
						}

						img {
							margin: 0 0 0 2rem;
							width: auto;
						}

						& > div {
							border-left: .1rem solid $light-gray;
						}

						h5 {
							padding: 0 0 2rem 2rem;
							margin: 2rem 0 0 0;
							color: $darker-gray;
							font-size: 1.8rem;
							font-weight: 500;
							line-height: 2rem;
						}

						ul {
							padding: 0 0 0 1.5rem;
							border-left: 1rem solid transparent;

							& + ul {
								margin-top: 4rem;
							}

							&.a-req {
								border-color: $data-red;
							}

							&.req {
								border-color: $data-orange;
							}

							&.opt {
								border-color: $data-green;
							}

							&.upwing {
								border-color: $data-blue;
							}

							&:last-of-type {
								padding-bottom: 0;
							}

							li {
								font-size: 1.4rem;
								line-height: 1.2;
								font-weight: 400;
								padding: 0 0 2rem 0;

								&:last-child {
									padding-bottom: 0;
								}
							}

						}

					}

					ul {
						@include reset-list;

						li {
							&:before {
								display: none;
							}
						}
					}
				}
			}

			.output-data {
				ul {
					@include reset-list(flex,flex);
					flex-flow: row wrap;

					li {
						margin: 0 2.5rem 2.5rem 0;
						flex-flow: column nowrap;

						&.production {
							img {
								width: 8.3rem;
								height: 9.6rem;
							}
						}
						&.pressure {
							img {
								width: 10.5rem;
								height: 9.5rem;
							}
						}
						&.compressor {
							img {
								width: 10rem;
								height: 10rem;
							}
						}
						&.power {
							img {
								width: 9.5rem;
								height: 9.5rem;
							}
						}
						&.forecast {
							img {
								width: 9.6rem;
								height: 9.6rem;
							}
						}
					}
				}

				&__img {
					width: 14rem;
					height: 14rem;
					background: $dark-blue;
					display: flex;
					flex-flow: row nowrap;
					justify-content: center;
					align-content: center;
					align-items: center;
				}

				&__title {
					width: 14rem;
					font-size: 1.6rem;
					line-height: 1.2;
					font-weight: 500;
					color: $darker-gray;
					margin: 1rem 0;

					@include bp(l) {
						font-size: 1.8rem;
					}
				}

				&__link {
					font-size: 1.4rem;
					display: block;
					margin-top: auto;
					text-transform: lowercase;
				}
			}
		}
	}
}