.layout-sidebar-first {
	#block-innovations,
	#block-onshoreproduction {
		ul.menu {
			// Disable "Navigation" link on desktop
			li:first-child {
				display: none;
			}
		}
	}
	ul.menu {
		@include reset-list;
		display: none;

		@include bp(l) {
			display: block;
			margin-top: 17rem;
		}

		a {
			display: block;
			padding: 1rem 0;
			color: $gray;
			transition: color .25s ease;

			&:hover {
				color: $blue;
			}

			&.is-active {
				font-weight: bold;
				color: $blue;
			}
		}
	}
	nav select {
		width: 100%;
		border: .1rem solid $light-gray;
		margin-top: 4rem;
		font-family: $roboto;
  	color: $gray;
  	font-size: 1.6rem;
  	padding: .5rem;

  	@include bp(l) {
  		display: none;
  	}
	}
}