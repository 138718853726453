.node--type-team-page {
	.layout-content > div:last-child {
		transition: all .5s ease-out;
		transform: translateY(5rem);
	}
	
	#block-upwing-page-title h1 {
		font-weight: 400;
		color: $dark-blue;
		font-size: 3rem;
		margin-top: 4rem;

		@include bp(m) {
			font-size: 4.2rem;
			padding-left: 35%;
		}
		@include bp(xl) {
			font-size: 4.6rem;
		}
	}

	.bio {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: 3rem;

		@include bp(m) {
			flex-flow: row nowrap;
		}

		&__image {
			flex: 0 0 35%;
			text-align: center;
			margin-bottom: 2rem;

			@include bp(m) {
				padding-right: 5%;
				margin-bottom: 0;
				text-align: left;
			}

			img {
				width: 100%;
				max-width: 18rem;
				
				@include bp(s) {
					max-width: 22rem;
				}

				@include bp(m) {
					max-width: 24.4rem;
				}
			}
		}
		&__text {
			flex: 0 1 auto;
		}
		&__name {
			@include aa;
			font-weight: 400;
			font-size: 1.8rem;
			margin: 0;
			padding: 0;
			color: $dark-gray !important;

			@include bp(m) {
				font-size: 2rem;
			}
			@include bp(l) {
				font-size: 2.4rem;
			}
			@include bp(xl) {
				font-size: 3rem;
			}
		}
		&__title {
			@include aa;
			font-weight: 300;
			color: $dark-gray;
			font-size: 1.6rem;
			margin: 0;
			padding: 0;

			@include bp(m) {
				font-size: 1.8rem;
			}
			@include bp(l) {
				font-size: 2rem;
			}
			@include bp(xl) {
				font-size: 2.6rem;
			}
		}
		&__description {
			font-size: 1.3rem;

			@include bp(m) {
				font-size: 1.4rem;
			}
			@include bp(l) {
				font-size: 1.7rem;
			}
		}
	}
}