.node--type-event {
	#block-upwing-content {
		.node {
			&__content {
				display: flex;
				flex-flow: column nowrap;

				@include bp(m) {
					flex-flow: row nowrap;
					overflow: visible;
				}
			}

			&__details {
				@include full-width;
				min-width: 100%;
				background: $dark-blue;
				color: $white;
				padding: 2rem;

				& > div {
					margin: 0 auto;
					max-width: 100rem;

					& > * {
						@include bp(m) {
							max-width: 50%;
						}
					}
				}

				h3 {
					@include aa;
					font-weight: 300;
					font-size: 3.2rem;
					letter-spacing: -0.05rem;
					margin: 0;
				}

				h3 + p {
					@include aa;
					font-size: 2.2rem;
					margin: 0;
				}

			}

			&__body {

				img {
					margin-top: 3rem;
					max-width: 50%;
				}

				h4 {
					@include aa;
					color: $dark-blue;
					font-size: 2rem;
					font-weight: 500;
					margin: 0;
				}

				@include bp(m) {
					width: 50%;
					margin-right: 5rem;
				}
			}

			&__form {
				background: $lighter-gray;
				padding: 0 2rem 2rem;

				@include bp(m) {
					width: 50%;
					position: relative;
				}

				&:before {
					@include bp(m) {
						content:"";
						position: absolute;
						top: -5rem;
						left: 0;
						width: 100%;
						height: 5rem;
						background: $lighter-gray;
					}
				}

				form {

					.form-item {
						margin-bottom: 2rem;
					}

					label {
						@include aa;
						display: block;
						margin-bottom: .5rem;
						color: $dark-gray;
						font-size: 1.6rem;
						font-weight: 700;
					}

					input {
						width: 100%;
						max-width: 100%;

						&.button {
							display: table;
							max-width: 22rem;
							margin: 3rem auto 0;
							cursor: pointer;
							color: $white;
							font-weight: 700;
							text-transform: none;
							border: none;
							background-color: $dark-blue;
							transition: all .25s ease;

							&:hover {
								background: $blue;
							}
						}
					}

					.js-form-type-webform-markup {
						margin-bottom: 3rem;
						text-align: center;

						h2 {
							@include aa;
							font-weight: 300;
							font-size: 3.8rem;
							margin: 0;
							padding: 0;

							@include bp(l) {
								font-size: 4.2rem;
							}
						}

						p {
							font-size: 1.8rem;
							margin: 0;
							padding: 0 10%;

							@include bp(l) {
								font-size: 2.2rem;
							}
						}
					}
				}
			}
		}
	}

	// Disable nav & footer
	#block-mainnavigation,
	footer[role='contentinfo'] {
		display: none;
	}
}