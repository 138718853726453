html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  font-size: 62.5%;
  background-color: $white;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html.with-featherlight {
	overflow: visible;
}

.featherlight {
	overflow: auto;
	width: 100vw;
	
	.featherlight-content {
		overflow: visible;
		max-height: none;
		max-width: 100rem;
		margin: 5rem 10rem;
	}

	&-iframe {
		.featherlight-content {
			max-width: none;
		}
	}
}

.featherlight button.featherlight-close {
	color: $white;
	background: $black;
	border: .3rem solid $white;
	border-radius: 50%;
	font-size: 2.5rem;
	height: 4rem;
	width: 4rem;
	line-height: 3rem;
	text-align: center;
	margin: -2rem -2rem 0 0;
	box-shadow: 0 0 2rem rgba($black, .15)
}

.sticky {
	position: sticky;

	&:before,
	&:after {
	  content: "";
	  display: table;
	}
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	height: 1px;
	min-height: 100vh;
	font-size: 1.4rem;
  line-height: 1.5;
  font-family: $roboto;
  color: $gray;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

hr {
	border: none;
	height: .1rem;
	background: $light-gray;
	margin: 4rem 0 4.5rem;
}

hr + p a {
	@extend .button--download;
}

figure {
	padding: 0;
	margin: 0;

	figcaption {
		margin: 1.5rem 0 3rem;
		font-size: 1.6rem;
		font-style: italic;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;

	&.align-left {
		max-width: 35%;
		margin-right: 5%;
	}

	&.align-right {
		max-width: 35%;
		margin-left: 5%;
	}
	&.align-center {
		@include bp(m) {
			max-width: 50%;	
		}
	}
}

p {
	margin: 2rem 0;
}

a {
	text-decoration: none;
}

.blue-tri {
	position: relative;

	&:after {
		content:"";
		display: inline-block;
		margin-left: .8rem;
		height: 0;
		width: 0;
		border: .5rem solid transparent;
		border-left-color: $dark-blue;
	}
}

table {
	caption {
		caption-side: bottom;
		font-style: italic;

		@include bp(m) {
			text-align: left;
		}
	}
}

#block-contactbottom {
	display: none;
}