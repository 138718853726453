main[role="main"] {
	@include global-padding;
	position: relative;	
	display: flex;
	flex-flow: column nowrap;

	@include bp(l) {
		flex-flow: row wrap;
	}

	@include bp(xl) {
		max-width: 130rem;
	}

	&.no-page-header {
		[class^='layout-'] {
			padding-top: 8rem;

			@include bp(l) {
				padding-top: 10vw;
			}

			@include bp(xxl) {
				padding-top: 16rem;
			}
		}
	}
}

.layout-content {
	flex: 1 1 calc(100% - 24rem);
	order: 2;

	@include bp(l) {
		width: calc(100% - 28rem);
	}

	&:last-child {
		width: 100%;
		max-width: 120rem;
		margin-left: auto;
		margin-right: auto;
	}
}

// Disable top padding on main content when sidebar is present
main.left-sidebar.no-page-header .layout-content {
	@include bp(l_max) {
		padding-top: 0;
	}
}

.layout-sidebar-first {
	order: 1;
	padding-right: 4rem;
	flex: 0 1 auto;

	@include bp(l) {
		flex: 0 1 24rem;
	}
}